import React, {Component} from 'react';
import {
  View,
  Text,
  ScrollView,
  Dimensions,
  TouchableOpacity
} from 'react-native';
import moment from 'moment';
import CommonStyles from '../../style/commonStyles';
import i18n from 'i18n-js';
export default class Activities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activitiesActive: true,
      exceptionActive: false,
      saveAsTextLabel: i18n.t("activityException.buttons.saveAsText"),
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  updateDimensions = () => {
    this.setState({windowWidth: window.innerWidth});
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  textFileDownload = () => {
    this.props.activityTextDownload();
  };
  render() {
    let activities = this.props.activities;
    return (
      <View
       testID='activitiesContainer'
      >
        <View style={{width: '100%'}}>
          <TouchableOpacity
            testID='textFileDownload'
            onPress={() => {
              {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.textFileDownload();
              }
            }}
          >
            <Text
              style={{
                color: '#ffff',
                fontFamily: 'Poppins_400Regular',
                marginLeft: this.state.windowWidth >= 435 ? '80%' : '73%',
                marginTop: this.state.windowWidth >= 435 ? '' : '8%'
              }}
            >
              {this.state.saveAsTextLabel}
            </Text>
          </TouchableOpacity>
          <View style={{flexDirection: 'row', marginTop: 14}}>
            <View
              style={CommonStyles.activityView}
            >
              <View>
                {activities.map((log, index) => {
                  return (
                    <>
                      <View
                        key={index}
                        testID='activityContainer'
                        style={CommonStyles.activityContainer}
                      >
                        <View style={{flex: 19}}>
                          <Text style={{color: 'white', fontSize: 15}}>
                            {moment(log.activitytime.split(".")[0]).format(
                              'dddd L, h:mm:ss a'
                            )}
                          </Text>

                          <Text
                            style={CommonStyles.logStyle}
                          >
                            {log.action} -by: {log.username} {log.platform}
                          </Text>
                        </View>
                      </View>
                    </>
                  );
                })}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
