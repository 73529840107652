import React,{useState,useEffect} from 'react';
import {LinearGradient} from 'expo-linear-gradient';
import { fontFamily } from '../../helpers/fontFamily';
import { Image, Text, View, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import {connect} from 'react-redux';
import { useDispatch } from 'react-redux';
import { MailOutOfStockReport, removeItem } from '../../helpers/suggestedMenuFunctions';
import { FontAwesome } from '@expo/vector-icons';
import i18n from 'i18n-js';


const DisplaySuggestedImage = (props) => {
  const [showDefault, setShowDefault] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const dispatch = useDispatch();
  let windowWidth= Dimensions.get('window').width

  let slider_image = [];

  useEffect(()=>{
  if(props.images?.length < 1){
      setShowDefault(true)
      return
  }
  setShowDefault(false)
  },[props.images[0]?.image])

  useEffect(() => {
    if(isRemoved){
      setTimeout(() => {
        props?.onPressGetOrderData();
      }, [1000]);
      setIsRemoved(false);
    }
  }, [props.removeOrderItems, isRemoved]);

  return (
    <View
      style={style.container}
    >
      <LinearGradient
        locations={[0, 0.03, 0.06]}
        start={{ x: 0.9, y: 1 }}
        colors={['#3d4851', '#9EA4A8', '#ffffff']}
        style={[style.gradient,{width: props.getWidth(),height: props.getWidth()}]}
      >
        {props.images?.map(
          (image, index) => {
            slider_image.push(image.image);
          }
        )}
        {showDefault ?(
          <Image
            onError={props.onError}
            source={props.source}
            style={[style.image, {top: showMenu ? '29%' : 0  }]}
          />
        ) : (
          <Image
            source={{uri:slider_image[0]}}
            onError={()=>{
              setShowDefault(true)
            }}
            style={[style.image, {top: showMenu ? '29%' : 0  }]}
          />
        )}
        <View
          dataSet={{ componentName: 'nextItemImageCount' }}
          style={[style.container1,{marginRight: props.getMargin()}]}>
          <Text
            style={{
              fontWeight: 'bold',
              fontFamily: fontFamily.font700,
              fontSize: 38,
              marginTop: 1,
              background:
                '-webkit-linear-gradient( #5a758d, #324a64)',
              webkitBackgroundClip: 'text',
              webkitTextFillColor: 'transparent',
              lineHeight: 32
            }}>
            {
              props.scannedQty
            }{' '}
            /{' '}
            {props.totalQty}
          </Text>
          <Text
            style={style.moreToScanText}>
            {props.remainingQty}{' '}
            {i18n.t("scanpackItem.moreToScanLabel")}
          </Text>
        </View>
        {!showMenu ? (
        <TouchableOpacity
          testID='menuIcon'
          onPress={()=>{setShowMenu(true)}}
          style={style.iconContainer}>
            <i
              className="icon-menu"
              style={{color: 'gray', fontSize: 42, marginLeft: -8, marginTop: 5}}>
            </i>
          </TouchableOpacity>
        ):(
        <View style={[style.buttonContainer, {width: props.getWidth(),height: props.getWidth()/2, zIndex: 9999, padding: 15}]}>
          <View style={style.shade} />
          <TouchableOpacity style={style.button} testID='OutOfStockReport' onPress={() => {
            setShowMenu(false);
            MailOutOfStockReport(props)
          }}>
            <Text style={style.buttonText}>{i18n.t("scanpackItem.outOfStockLabel")}</Text>
            <i
                class="icon-comment"
                style={{ color: '#fff', fontSize: 30 }}
            />
          </TouchableOpacity>
          <TouchableOpacity style={style.button} testID='RemoveItem' onPress={() => {
            setShowMenu(false);
            setIsRemoved(true);
            removeItem(props, dispatch);
          }}>
            <Text style={style.buttonText}>Remove</Text>
            <FontAwesome name="bomb" size={30} color={'#fff'}></FontAwesome>
          </TouchableOpacity>
          <TouchableOpacity testID='CloseButton' style={[style.button, { top: windowWidth < 1400 ? '55%': '85%', justifyContent: 'center' }]} onPress={()=>{setShowMenu(false)}}>
            <Text style={style.buttonText}>Close</Text>
          </TouchableOpacity>
        </View>
        )}
      </LinearGradient>
    </View>
  )
}

const mapStateToProps = state => {
  return {
    removeOrderItems: state.order.removeOrderItems,
  };
};

export default connect(mapStateToProps) (DisplaySuggestedImage);

const style = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: '100%',
  },
  shade: {
    borderRadius: '21px',
    position: 'absolute',
    top: 10,
    bottom: '-106%',
    left: -4,
    right: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
  },
  iconContainer: {
    flex: 1,
    width: '100%',
    bottom: '95%',
    marginLeft: '70%'
  },
  gradient: {
    backgroundColor: '#000000',
    position: 'relative',
    zIndex: 9,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 18,
    borderBottomLeftRadius: 18,
    shadowColor: '#000000',
    shadowOpacity: 0.58,
    marginTop: '2%',
    shadowRadius: 29,
    shadowOffset: {
      width: 5,
      height: 29
    }
  },
  image: {
    height: '96%',
    width: '93%',
    resizeMode: 'cover',
    borderBottomLeftRadius: 18,
    borderTopLeftRadius: 18,
    marginRight: 31,
  },
  text: {
    fontWeight: 'bold',
    fontFamily: fontFamily.font700,
    fontSize: 38,
    marginTop: 1,
    webkitBackgroundClip: 'text',
    webkitTextFillColor: 'transparent',
    lineHeight: 32
  },
  container1: {
    width: '93%',
    position: 'absolute',
    textAlign: 'center',
    fontSize: 18,
    fontFamily: fontFamily.font600,
    backgroundColor: '#fff',
    borderBottomLeftRadius: 18,
    opacity: 0.7,
    bottom: 0,
    right: 0,
    left: 0,
    marginLeft: 'auto',
    zIndex: 999
  },
  moreToScanText: {
    textAlign: 'center',
    fontSize: 18,
    color: '#000',
    fontFamily: fontFamily.font700,
    lineHeight: 19
  },
  buttonContainer: {
    padding: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    bottom: '75%'
  },
  button: {
    backgroundColor: '#3d4851',
    padding: 10,
    borderRadius: 5,
    margin: 5,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  buttonText: {
    color: '#fff',
    fontSize: 20,
    fontFamily: fontFamily.font700
  }
});
