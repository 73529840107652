import React, { Component, useRef } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Platform,
  Dimensions,
  Image
} from 'react-native';
import styles from '../../style/scanpack';
import i18n from 'i18n-js';
import { LinearGradient } from 'expo-linear-gradient';
import CustomScrollBar from './custom_scrollbar';
import DefaultInput from '../../commons/Inputs/defaultInput';
import commonStyles from '../../style/commonStyles';
import globalStyles from '../../style/global';
export default class ProductConfirmationCheck extends Component {
  constructor(props) {
    super(props);
    this.typeScanFocus = React.createRef();
    this.state = {
      windowWidth: Dimensions.get('window').width
    };
  }
  componentDidUpdate() {
    this.typeScanFocus?.current?.focus();
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  getInstructions = () => {
    if(this.props.kitInstructions !== ''){
      return this.props.kitInstructions
    }
    else {
      return this.props?.productInstructions !== '' ? this.props.productInstructions :
      this.props?.order?.order?.unscanned_items[0]?.child_items?.length > 0
        ? this.props?.order?.order?.unscanned_items[0]?.child_items[0]
          ?.instruction
        : this.props?.order?.order?.unscanned_items[0]?.instruction;
    }
  }

  render() {
    let skus = this.props?.sku !== '' ? this.props?.sku :
      this.props?.order?.order?.unscanned_items[0]?.child_items?.length > 0
        ? this.props?.order?.order?.unscanned_items[0]?.child_items[0]
          ?.sku
        : this.props?.order?.order?.unscanned_items[0]?.sku;
    let instructionCheckNew = this.props?.order?.order?.scanned_items[0]?.instruction

    let instructionCheck = this.getInstructions()
    instructionCheck = instructionCheck?.replace(/<\/?[^>]+(>|$)/g, "");
    return (
      <View
      testID='productConfirmationModal'
      style={[styles.responseView, { zIndex: 8, width: '100%' }]}>
        {this.state.windowWidth >= 900 ? (
          <View style={styles.typeScanContainer}>
            <LinearGradient
              colors={['#fdf359', '#d2c609']}
              style={[commonStyles.productConfirmation,globalStyles.productConfirmation]}
            >
              <View>
                <View
                  style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <Text
                    dataSet={{ componentName: 'productInstructionForSku' }}
                    style={[
                      styles.scanCountText, commonStyles.productInstruction,{ color: " #000000", } ]}
                  >
                    {i18n.t("productConfirmationPopup.productInstruction")}{' '} {skus}
                  </Text>
                  <TouchableOpacity
                  testID='CancelIcon'
                  dataSet={{ componentName: 'instructionCloseButton' }}
                    onPress={this.props.closeProductConfirmationCheckPopUp}
                  >
                    <i
                      className="icon-cancel"
                      style={{
                        width: "17.19px",
                        height: "17.19px",
                        fontSize: '26px',
                        color: '#000',
                        marginTop: 4,
                        order: 0,
                        marginLeft: "-70%",
                        flexGrow: 0,
                      }}
                    ></i>
                  </TouchableOpacity>
                </View>

              </View>
              <View
                dataSet={{ componentName: 'instructionValue' }}
                style={globalStyles.customScrollBar}
              >
                <CustomScrollBar instructionCheck={instructionCheck} />
              </View>

              <View
                style={[commonStyles.flexStyle,{ marginTop: '3rem' }]}
              >
                <Text
                  dataSet={{ componentName: 'instructionHeadingText' }}
                  style={[ styles.scanCountText, commonStyles.productConfirmationTest ]}
                >
                  {i18n.t('productConfirmationPopup.productConfirmationTest')}
                </Text>
                <View
                  style={[commonStyles.checkInputStyle, globalStyles.ProductCheckInputStyle,{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                  }]}
                >
                  <TextInput
                    dataSet={{ componentName: 'instructionTextInput' }}
                    onChangeText={this.props.handleProductConfirmationCheckInput}
                    placeholder={i18n.t('productConfirmationPopup.inputPlaceHolder')}
                    autoFocus={true}
                    value={this.props.ProductConfirmationCheckInput}
                    ref={this.typeScanFocus}
                    onSubmitEditing={this.props.ProductConfirmationCheckFun}
                    style={globalStyles.ProductConfirmationCheckInput}
                  ></TextInput>
                </View>
              </View>
            </LinearGradient>
          </View>
        ) : (
          <View style={[styles.typeScanContainer, { justifyContent: 'flex-end', position: 'fixed', bottom: '-20%' }]}>
            <LinearGradient
              // locations={[0, 0.13, 1]}
              colors={['#FFF973', '#EFDA1D']}
              style={[commonStyles.productConfirmation, globalStyles.productConfirmationMobile]}
            >
              <View>
                <View
                  style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <Text
                    style={[
                      styles.scanCountText, commonStyles.productInstruction,{ width: 337 } ]}
                  >
                    {i18n.t("productConfirmationPopup.productInstruction")}{' '}{skus}
                  </Text>
                  <TouchableOpacity
                    testID='CancelIcon'
                    onPress={this.props.closeProductConfirmationCheckPopUp}
                  >
                    <i
                      dataSet={{ componentName: 'instructionClose' }}
                      className="icon-cancel"
                      style={{
                        fontSize: '26px',
                        color: '#000',
                        marginTop: -2,
                      }}
                    ></i>
                  </TouchableOpacity>
                </View>
              </View>
              <View
                style={[globalStyles.productConfirmationView,{
                  background: "rgba(255, 255, 255, 0.3)",
                }]}
              >
                <ScrollView>
                  <Text
                    style={globalStyles.scrollView}
                  >
                    {instructionCheck}{' '}
                  </Text>
                </ScrollView>
              </View>

              <View
                style={commonStyles.flexStyle}
              >
                <Text
                  style={[
                    styles.scanCountText, globalStyles.scanCountText ]}
                >
                  {i18n.t('productConfirmationPopup.productConfirmationTest')}
                </Text>
                <View style={[commonStyles.checkInputStyle, globalStyles.ProductCheckInputStyleMobile,{
                  backgroundColor: "rgba(255, 255, 255, 0.4)"
                }]}>
                  <DefaultInput
                    onChangeText={this.props.handleProductConfirmationCheckInput}
                    placeholder={i18n.t('productConfirmationPopup.inputPlaceHolder')}
                    autoFocus={true}
                    value={this.props.ProductConfirmationCheckInput}
                    ref={this.typeScanFocus}
                    returnKeyType='done'
                    onSubmitEditing={this.props.ProductConfirmationCheckFun}
                    style={globalStyles.productConfirmationCheckMobile}
                  />
                </View>
              </View>
            </LinearGradient>
          </View>
        )}
      </View>
    );
  }
}
