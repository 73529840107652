import React, {useEffect, useState} from 'react';
import {Text, View, StyleSheet} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {LinearGradient} from 'expo-linear-gradient';
import {useSelector} from 'react-redux';
import { getLiveStatsData } from '../helpers/scanPackFunctions';
import LabelWithCount from './labelWithCount';
import I18n from 'i18n-js';

const DisplayLiveStats = () => {
  const dashboardData = useSelector(state => state.scanpack.dashboardData);
  const [userInfo, setUserInfo] = useState(null);
  const [leaderOrderCount, setLeaderOrderCount] = useState(0);
  const [userOrderCount, setUserOrderCount] = useState(0);
  const [isUserLeading, setIsUserLeading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const storedUserInfo = await AsyncStorage.getItem('userInfo');
        if (storedUserInfo) {
          setUserInfo(JSON.parse(storedUserInfo));
        }
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

useEffect(() => {
  if (dashboardData?.daily_user_data?.packing_stats?.length > 0 && userInfo) {
    const packingStats = dashboardData.daily_user_data.packing_stats;
    const currentUser = userInfo?.username;
    let maxCount = 0;
    let userCount = 0;
    packingStats.forEach(user => {
      const index = getCurrentDateIndex(user.values);
      const lastEntry = user.values[index];
      const entryDate = lastEntry[0];
      const packedItemsCount = lastEntry[3];
      if (isCurrentAndEntryDateSame(entryDate)) {
        if (user.key === currentUser) {
          userCount = packedItemsCount;
        }
        else{
          maxCount = Math.max(maxCount, packedItemsCount);
        }
      }
    });

    setUserOrderCount(userCount);
    setLeaderOrderCount(maxCount);
    setIsUserLeading(userCount > maxCount);
  }
}, [dashboardData, userInfo]);

useEffect(() => {
  const fetchData = async () => {
    if (isDataFetched === false) {
      await getLiveStatsData();
      setIsDataFetched(true);
    }
  };

  fetchData();
}, [leaderOrderCount, userOrderCount, isDataFetched]);

useEffect(()=>{
  const fetchData = async () => {
      await getLiveStatsData();
  };
  fetchData();

  setTimeout(() => {
    fetchData();
  }, 5000)
},[])

const getCurrentDateIndex = liveStatsData => {
  const index = liveStatsData.findIndex(entry =>
    isCurrentAndEntryDateSame(entry[0])
  );
  if (index > -1) {
    return index;
  }
  return liveStatsData.length - 1;
};

const isCurrentAndEntryDateSame = entryTimestamp => {
  const currentDate = new Date();
  const entryDate = new Date(entryTimestamp * 1000);
  return (
    currentDate.getFullYear() === entryDate.getUTCFullYear() &&
    currentDate.getMonth() === entryDate.getUTCMonth() &&
    currentDate.getDate() === entryDate.getUTCDate()
  );
};

  return (
    <View style={styles.dashboardContainer}>
      <LeaderBoard
        leaderOrderCount={leaderOrderCount}
        userOrderCount={userOrderCount}
        isUserLeading={isUserLeading}
      />
    </View>
  );
};

const LeaderBoard = ({leaderOrderCount, userOrderCount, isUserLeading}) => {
  return (
    <View style={styles.leaderBoardContainer}>
      <LinearGradient
        locations={[0, 1]}
        colors={['#00000006', '#393535']}
        style={styles.fullWidthGradient}>
        <View style={styles.paddingBox}>
          {isUserLeading ? (
            <LeaderMessage lead={userOrderCount} />
          ) : (
            <TrailingMessage
              userCount={userOrderCount}
              leaderCount={leaderOrderCount}
            />
          )}
        </View>
      </LinearGradient>
    </View>
  );
};

const TrailingMessage = ({userCount, leaderCount}) => {
  return (
    <View style={styles.trailingMessageContainer}>
      <ScoreCard label={I18n.t("nextItems.youLabel")} count={userCount} />
      <ScoreCard label={I18n.t("nextItems.leaderLabel")} count={leaderCount} />
    </View>
  );
};

const ScoreCard = ({label, count}) => (
  <LinearGradient
    locations={[0, 1]}
    colors={['#00000006', '#393535']}
    style={styles.gradientBox}>
    <Text style={styles.text}>{`${label}: ${count}`}</Text>
  </LinearGradient>
);

const LeaderMessage = ({lead}) => {
  return (
    <LabelWithCount label={`${I18n.t("nextItems.youAreInLead")} ${lead}`} />
  );
};

const styles = StyleSheet.create({
  dashboardContainer: {
    textAlign: 'left',
    marginBottom: 5,
    marginTop: 10
  },
  leaderBoardContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fullWidthGradient: {
    width: '100%'
  },
  paddingBox: {
    padding: 5
  },
  trailingMessageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  gradientBox: {
    borderRadius: 25,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderColor: '#373838',
    borderWidth: 1
  },
  text: {
    color: 'white',
    fontFamily: 'Poppins_300Light',
    fontWeight: 'bold',
    fontSize: 16
  },
});

export default DisplayLiveStats;
