import React, {useEffect, useState} from 'react';
import {View,Text,TouchableOpacity,StyleSheet} from 'react-native';
import { fontFamily } from '../helpers/fontFamily';
import { LinearGradient } from 'expo-linear-gradient';
import i18n from 'i18n-js';
const ScanAllButton = ({
  onPress,
  order,
  windowWidth,
  orderItem,
}) => {
  const [totalScanned, setTotalScanned] = useState(0);

  const calculateTotal = (item) => {
    return item.total_qty
  };

  const setKitCount = (item) => {
    if (
      item.child_items &&
      item.child_items.length > 0 &&
      item.product_type === 'individual'
    ) {
      return item.child_items[0].scanned_qty + item.child_items[0].qty_remaining;
    }
  }

  useEffect(() => {
    orderItem.child_items.length > 0 ? setTotalScanned(setKitCount(orderItem)) : setTotalScanned(calculateTotal(orderItem));
  }, [order.unscanned_items[0].child_items[0], orderItem])

  const totalSum = totalScanned;

  return(
    <LinearGradient
    locations={[0,1]}
    colors={[
      '#FFF973',
      '#EFDA1D',
    ]}
    style={[windowWidth > 900 ? styles.passButton: styles.passButtonMobile]}>
      <TouchableOpacity
        testID='scanAllButton'
        onPress={()=>{
          onPress()
        }}
        delayLongPress={1000}
        style={{flexDirection: 'row'}}
        dataSet={{ componentName: 'scanAllButton'}}
      >
      <Text
        style={windowWidth > 900 ? styles.passButtonText: styles.passButtonTextMobile}
      >
          {i18n.t("nextItems.scanAllLabel")}
      </Text>
      <Text
      style={[totalScanned >= 100 ? styles.itemNumberStyleSecond : styles.itemNumberStyle]}
      >
        {totalSum}
      </Text>
      </TouchableOpacity>
    </LinearGradient>
  )
}
const styles = StyleSheet.create({
  passButton:{
    width: 90,
    alignSelf: 'center',
    marginLeft: 5,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    borderRadius: 4,
    border: '1px solid #E6DF26',
    boxShadow: '0px 0px 6px 4px rgba(255, 255, 255, 0.50)'
  },
  passButtonMobile:{
    width: 79,
    marginLeft: 5,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 7,
    borderRadius: 4,
    border: 1,
    height: 32,
    paddingLeft: 3,
    paddingRight: 3,
    border: '1px solid #E6DF26',
    boxShadow: '0px 0px 6px 4px rgba(255, 255, 255, 0.50)'
  },
  passButtonText: {
    fontFamily: fontFamily.font400,
    fontSize: 14,
    paddingTop: 5,
    paddingBottom: 5,
    color: '#000000',
    textAlign: 'right',
    paddingRight: 2, //
    lineHeight: 17 //
  },
  passButtonTextMobile: {
    fontFamily: fontFamily.font400,
    fontSize: 14,
    color: '#000000',
    textAlign: 'right',
    lineHeight: 12,
    paddingTop: 5,
    paddingBottom: 5,
  },
  itemNumberStyle:{
    fontFamily: fontFamily.font600,
    fontSize: 30,
    lineHeight: 27.75,
    color: '#000000',
    textAlign: 'center',
    paddingTop: 4,
    paddingBottom: 1,
    padding: 2
  },
  itemNumberStyleSecond:{
    fontFamily: fontFamily.font600,
    fontSize: 18,
    lineHeight: 30.65,
    color: '#000000',
    textAlign: 'center',
    padding: 1
  }
})
export default ScanAllButton;
