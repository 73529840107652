import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { fontFamily } from "../helpers/fontFamily";
import I18n from "i18n-js";
const OrderItemListMenu = ({
  showScannedList,
  showUnScannedList,
  showLogList,
  unScannedCount,
  scannedCount,
  scannedList,
  unScannedList,
  logList,
  navButtonLeft,
  icon,
}) => {
  return (
    <View style={{ backgroundColor: "#000" }}>
      <LinearGradient
        style={{
          flexDirection: "row",
          backgroundColor: "#707070",
          borderRadius: 10,
          marginTop: 1,
          marginBottom: 1,
        }}
        start={{ x: 0, y: 1 }}
        end={{ x: 0, y: 0 }}
        locations={[0, 1]}
        colors={["#000000", "#3a3a3a"]}
      >
        <Button
          onPress={showScannedList}
          count={unScannedCount}
          label={I18n.t("nextItems.unScannedLabelMobile")}
          show={scannedList}
        />
        <Button
          onPress={showUnScannedList}
          count={scannedCount}
          label={I18n.t("nextItems.scannedLabelMobile")}
          show={unScannedList}
          id={"ScannedListBtn"}
        />
        <Button
          onPress={showLogList}
          label={I18n.t("nextItems.activityLogLabel")}
          show={logList}
          id={"ActivityLogBtn"}
        />
        <TouchableOpacity
          onPress={navButtonLeft}
          style={{ marginRight: 10, marginTop: 5 }}
        >
          <Image style={{ width: 20, height: 20 }} source={icon ? icon : ""} />
        </TouchableOpacity>
      </LinearGradient>
    </View>
  );
};
export default OrderItemListMenu;

const Button = ({ onPress, count = "", label, id, show }) => {
  return (
    <TouchableOpacity
      testID={id}
      onPress={onPress}
      style={{
        flex: 2,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 14,
            fontFamily: fontFamily.font500,
            color: "#c6c6c6",
            textAlign:'center',
          }}
        >
          {count + label}
        </Text>
        {show && (
          <View
            style={{
              width: "90%",
              height: 2,
              backgroundColor: "#555454",
            }}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};
