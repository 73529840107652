import React, {useState} from 'react';
import {View, StyleSheet, Text, TouchableOpacity} from 'react-native';

export const TagsCell = ({item}) => {
  const [hoveredTag, setHoveredTag] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (tag, index) => {
    setHoveredTag(tag);
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredTag(null);
    setHoveredIndex(null);
  };

  return (
    <TouchableOpacity style={styles.container} activeOpacity={1}>
      <View>
        <Text
          numberOfLines={1}
          style={[
            styles.tooltipText,
            {
              color: hoveredTag ? '#fff' : 'transparent',
              marginLeft:
                item?.order_info?.tags?.length > 1 ? 15 * hoveredIndex : '0%'
            }
          ]}>
          {hoveredTag?.name || 'dummy text'}
        </Text>
      </View>
      <View style={styles.tagsRow}>
        {item?.order_info?.tags?.map((tag, index) => (
          <TouchableOpacity
            key={tag?.id}
            style={[
              styles.tagBox,
              {boxShadow: `0px 0px 4px 1px ${tag.color}`},
              {backgroundColor: tag.color}
            ]}
            onMouseEnter={() => handleMouseEnter(tag, index)}
            onMouseLeave={handleMouseLeave}
          />
        ))}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 10
  },
  tagsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  tagBox: {
    width: 10,
    height: 15,
    marginHorizontal: 3,
    borderRadius: 2,
    transition: 'width 0.3s ease'
  },
  tooltipText: {
    color: '#fff',
    fontSize: 12,
    textAlign: 'left',
    fontFamily: 'Poppins_300Light'
  }
});
