import React, { Component } from 'react';
import { View, Text, TextInput, TouchableOpacity, Image } from 'react-native';
import { connect } from 'react-redux';
import styles from '../../style/productdetail';
import AddProduct from './addproduct';
import AlertBoxPermission from '../../commons/alertBoxPermission';
import {
  GETAllKITProduct,
  GetProductDetail,
  RemoveKITProduct,
  updateProductList
} from '../../actions/productAction';
import { LinearGradient } from 'expo-linear-gradient';
import { FontAwesome } from '@expo/vector-icons';
import i18n from 'i18n-js';
let updatecall = false;
let inventoryDropDownIndex = 0;
class InventoryKitOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kits: [],
      itemadd: false,
      selectedcat: '',
      toolTipVisible: false,
      quickScanToolTip3: false,
      removeIds: [],
      kits: [],
      kitselected: [],
      ChangeKitToProduct: true,
      quickScanToolTip3: false,
      addflag: true,
      dropDownToggleState: false,
      inventoryOption: '',
      lastSearch: '',
      editable: Number,
      toolTipCheck: false,
      permissionState: false,
      pickerSelectedOptions: [
        {
          label: i18n.t("product.pickerSelectedOptions.one"),
          value: 'single'
        },
        {
          label: i18n.t("product.pickerSelectedOptions.two"),
          value: 'individual'
        },
        {
          label: i18n.t("product.pickerSelectedOptions.three"),
          value: 'depends'
        }
      ],
      quickScanToolTip: false
    };
    window.addEventListener('resize', this.update);
  }
  permissionCheck = () => {
    if (this.props.show === true) {
      this.setState({ permissionState: true });
    }
  };
  onCloseAlert = () => {
    if (this.props.show === true) {
      this.setState({ permissionState: false });
    }
  };
  componentDidMount() {
    this.update();
    this.setState({ kits: [this?.props?.product?.productkitskus] });
    this.setState({
      inventoryOption:
        this.props?.product?.basicinfo?.kit_parsing == 'individual'
          ? i18n.t("product.pickerSelectedOptions.one")
          : this.props?.product?.basicinfo?.kit_parsing == 'single'
            ? i18n.t("product.pickerSelectedOptions.two")
            : this.props?.product?.basicinfo?.kit_parsing == 'depends'
              ? i18n.t("product.pickerSelectedOptions.three")
              : ''
    });

    let productID = this.props?.product?.basicinfo.id;
    this.props.GETAllKITProduct(productID);
    // let inventoryDropDownIndex = '';
    switch (this.props?.product?.basicinfo?.kit_parsing) {
      case 'single':
        inventoryDropDownIndex = 0;
        break;
      case 'individual':
        inventoryDropDownIndex = 1;
        break;
      case 'depends':
        inventoryDropDownIndex = 2;
        break;
      default:
        break;
    }
  }
  ToolTipset = () => {
    this.setState({
      quickScanToolTip: !this.state.quickScanToolTip
    });
  };
  componentDidUpdate() {
    if (updatecall == true) {
      updatecall = false;
      let productID = this.props?.product?.basicinfo.id;
      this.props.GETAllKITProduct(productID);
    }
  }
  AddItemToInventory = () => {
    const { product, GETAllKITProduct, GetProductDetail } = this.props;
    this.setState({ itemadd: false });
    let productID = product?.basicinfo.id;
    GETAllKITProduct(productID);
    GetProductDetail(productID);
  };

  kitinfo = () => {
    this.setState({ kits: this.props.productskit });
  };
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  update = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  async onValueChangeCat(value) {
    this.setState({ selectedcat: value });
  }

  removeKitUpdate = () => {
    let productID = this.props?.product?.basicinfo.id;
    this.props.GETAllKITProduct(productID);
  };
  removeKitItem(id) {
    let prodId = this.props?.product?.basicinfo?.id;
    let aa = [];
    aa.push(id);
    let data = {
      kit_products: aa
    };
    this.props.RemoveKITProduct(prodId, data);
    updatecall = true;
    this.removeKitUpdate();
  }

  handleChange(text, item) {
    let qtyValue = item;
    qtyValue['qty'] = text && JSON.parse(text);
    this.setState({ qtyValue });
    let Product = this.props?.product;
    Product.productkitskus.map((sku, index) => {
      if (item.option_product_id === sku.option_product_id) {
        Product.productkitskus.splice(index, 1, qtyValue);
      }
    });
    this.props.inventoryWarehousesHandleChange(
      Product.productkitskus,
      'productkitskus'
    );
  }

  removeItems = async () => {
    let removeIds = this.state.removeIds;
    let data = {
      orderitem: removeIds
    };
    if (this.state.removeIds && this.state.removeIds.length > 0) {
      await this.props.RemoveKITProduct(data);
      removeOrder = true;
    }
  };

  changekittoproduct = () => {
    const { product, productskit, RemoveKITProduct, GETAllKITProduct, changeState } = this.props;
    let productID = product?.basicinfo?.id;
    let kitselected1 = [];
    productskit.productskit.map((item, index) => {
      kitselected1.push(item.option_product_id);
    });
    let data = {
      kit_products: kitselected1
    };
    RemoveKITProduct(productID, data);
    setTimeout(() => {
      GETAllKITProduct(productID);
      changeState('productInfoActive');
    }, 1000);
  };
  updateQuantity = item => {
    if (item) {
      let data = {
        productskit: item.option_product_id,
        qty: item.qty && parseInt(item.qty)
      };

      this.props.updateProductList(data);
    }
  };

  dropDownToggle = () => {
    this.setState({ dropDownToggleState: !this.state.dropDownToggleState });
  };
  updatePicker = (itemValue, index) => {
    this.props.productInfoHandleChange(itemValue, 'kit_parsing');
    this.props.updateProductFun('save');
    this.dropDownToggle();
    inventoryDropDownIndex = index;
  };

  tooltipFun = () => {
    this.setState({ toolTipCheck: !this.state.toolTipCheck });
    this.setState({
      quickScanToolTip3: !this.state.quickScanToolTip3
    });
  };
  render() {
    let basicinfo = this?.props?.product?.basicinfo;
    let productskit = this.props.productskit.productskit;
    return (
      <>
        {!this.state.itemadd ? (
          <>
            <AlertBoxPermission
              permissionState={this.state.permissionState}
              onCloseAlert={() => this.onCloseAlert()}
            />
            {this.state.windowWidth >= 900 ? (
              <>
                <View
                  testID='kitContainer'
                  style={{
                    marginLeft: '30px',
                    marginTop: '-28px',
                    width: '70%',
                    display: 'none'
                  }}
                  onStartShouldSetResponder={() =>
                    this.state.toolTipCheck ? this.tooltipFun() : <></>
                  }
                >
                  {basicinfo?.is_kit == 1 ? (
                    <Text style={[styles.KitHeading]}>
                      KIT:{this.props.product?.skus?.[0]?.sku}
                    </Text>
                  ) : (
                    <Text style={[styles.KitHeading]}>
                      {this.props.product?.skus?.[0]?.sku}
                    </Text>
                  )}
                </View>
                <View
                  style={[styles.inventoryTolltip]}
                  onStartShouldSetResponder={() =>
                    this.state.toolTipCheck ? this.tooltipFun() : <></>
                  }
                >
                  <View
                    style={{
                      alignSelf: 'flex-center',
                      blocksize: 'inherit',
                      width: '100%',
                      flexDirection: 'row'
                    }}
                  ></View>
                  <View
                    style={{
                      marginTop: 0,
                      flexDirection: 'row',
                      alignContent: 'flex-end',
                      alignSelf: 'flex-end'
                    }}
                  >
                    {productskit?.length >= 1 ? (
                      <View>
                        <TouchableOpacity
                          testID='addItems'
                          onPress={() => {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.setState({ itemadd: true });
                          }}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <i
                            className="icon-plus"
                            style={{
                              color: 'white',
                              marginTop: '7%',
                              fontSize: '45px'
                            }}
                          ></i>
                          <Text
                            style={[styles.inventoryAddItemText, { marginLeft: 8, width: '100%' }]}
                          >
                            Add Items
                          </Text>
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <></>
                    )}
                  </View>
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: 'rgba(15, 39, 58, 0.4)',
                      borderRadius: 15,
                      marginBottom: 50,
                      height: 100
                    }}
                  >
                    <View style={[styles.itemContainerKit]}>
                      <View
                        style={[
                          styles.itemViewKit,
                          {
                            width: '2%',
                            marginHorizontal: '1%',
                            paddingVertical: 15
                          }
                        ]}
                      ></View>
                      <View
                        style={{
                          flexDirection: 'row',
                          paddingHorizontal: '1%',
                          width: '96%',
                          justifyContent: 'space-around',
                          flexWrap: 'wrap'
                        }}
                      >
                        <View
                          style={[
                            styles.itemViewKit,
                            {
                              width: '10%'
                            }
                          ]}
                        ></View>
                        <View style={[styles.itemViewKit, { width: '18%' }]}>
                          <Text style={[styles.gridHeaderKit]}>
                            Product Name
                          </Text>
                        </View>

                        <View style={[styles.itemViewKit, { width: '15%' }]}>
                          <Text style={[styles.gridHeaderKit]}>SKU</Text>
                        </View>

                        <View style={[styles.itemViewKit, { width: '8%' }]}>
                          <Text style={[styles.gridHeaderKit]}>Status</Text>
                        </View>

                        <View
                          style={[
                            styles.itemViewKit,
                            {
                              width: '10%',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }
                          ]}
                        >
                          <Text style={[styles.gridHeaderKit]}>QTY/KIT</Text>
                        </View>

                        <View
                          style={[
                            styles.itemViewKit,
                            {
                              width: '15%',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }
                          ]}
                        >
                          <Text style={[styles.gridHeaderKit]}>Location l</Text>
                        </View>
                        <View
                          style={[
                            styles.itemViewKit,
                            {
                              width: '4%',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }
                          ]}
                        >
                          <Text style={[styles.gridHeaderKit]}>QOH</Text>
                        </View>
                      </View>
                    </View>
                    <View>
                      {productskit &&
                        productskit.map((item, index) => {
                          return (
                            <View style={[styles.itemContainerKit]} key={index}>
                              <TouchableOpacity
                                testID='removeKitItem'
                                onPress={() => {
                                  this.props.show === true
                                    ? this.permissionCheck()
                                    : this.removeKitItem(
                                      item.option_product_id
                                    );
                                }}
                                style={[
                                  styles.itemViewKit,
                                  {
                                    width: '2%',
                                    marginHorizontal: '1%'
                                  }
                                ]}
                              >
                                <i
                                  className="icon-cancel"
                                  style={{
                                    color: 'white',
                                    marginLeft: -4,
                                    fontSize: '38px',
                                    marginTop: -5,
                                    resizeMode: 'stretch'
                                  }}
                                ></i>
                              </TouchableOpacity>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  borderBottomWidth: 0.7,
                                  borderColor: 'rgba(250, 250, 250, .4)',
                                  paddingHorizontal: '1%',
                                  width: '96%',
                                  justifyContent: 'space-around',
                                  flexWrap: 'wrap'
                                }}
                              >
                                <View
                                  style={[
                                    styles.itemViewKit,
                                    {
                                      width: '10%',
                                      marginVertical: 5
                                    }
                                  ]}
                                >
                                  <Image
                                    style={{
                                      width: 70,
                                      height: 70,
                                      borderRadius: 5
                                    }}
                                    source={{ uri: item?.images[0]?.image }}
                                  />
                                </View>
                                <View
                                  style={[styles.itemViewKit, { width: '18%' }]}
                                >
                                  <Text
                                    style={[
                                      styles.itemNameKit,
                                      { color: '#aad9fc' }
                                    ]}
                                  >
                                    {item.name}
                                  </Text>
                                </View>
                                <View
                                  style={[styles.itemViewKit, { width: '15%' }]}
                                >
                                  <Text style={[styles.itemNameKit]}>
                                    {item.sku}
                                  </Text>
                                </View>
                                <View
                                  style={[styles.itemViewKit, { width: '8%' }]}
                                >
                                  {item.product_status === 'active' ? (
                                    <Text style={[styles.statusActiveKit]}>
                                      {this.Capitalize(item.product_status)}
                                    </Text>
                                  ) : (
                                    <Text style={[styles.statusNewKit]}>
                                      {this.Capitalize(item.product_status)}
                                    </Text>
                                  )}
                                </View>
                                <View
                                  style={[
                                    styles.itemViewKit,
                                    {
                                      width: '10%',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }
                                  ]}
                                >
                                  {this.state.editable == index ? (
                                    <TextInput
                                      testID='quantityInput'
                                      placeholderTextColor="white"
                                      keyboardType="default"
                                      autoFocus={true}
                                      placeholder={item.qty}
                                      onChangeText={text =>
                                        this.handleChange(text, item)
                                      }
                                      onBlur={() => {
                                        {
                                          item.qty > 0
                                            ? (this.updateQuantity(item),
                                              this.setState({ editable: -1 }))
                                            : (this.props.removeIds.includes(
                                              item.option_product_id
                                            ),
                                              this.props.removeOrderItem(item),
                                              this.props.removeItems(),
                                              this.setState({ editable: -1 }));
                                        }
                                      }}
                                      style={{
                                        width: 25,
                                        height: 25,
                                        fontSize: 19,
                                        color: 'white'
                                      }}
                                    />
                                  ) : (
                                    <Text style={[styles.itemNameKit]}>
                                      {item.qty}
                                    </Text>
                                  )}
                                  <TouchableOpacity
                                    testID='editIcon'
                                    onPress={() => {
                                      this.props.show === true
                                        ? this.permissionCheck()
                                        : this.setState({ editable: index });
                                      // this.props.userRole.role.add_edit_products ?
                                      //   this.setState({ editable: index }) : <></>
                                      // this.changeEdit();
                                    }}
                                  >
                                    <i
                                      className="icon-edit"
                                      style={{
                                        color: 'white',
                                        fontSize: '20px'
                                      }}
                                    ></i>
                                  </TouchableOpacity>
                                </View>
                                <View
                                  style={[
                                    styles.itemViewKit,
                                    {
                                      width: '15%',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }
                                  ]}
                                >
                                  <Text style={[styles.itemNameKit]}>
                                    {item.primary_location}
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    styles.itemViewKit,
                                    {
                                      width: '4%',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }
                                  ]}
                                >
                                  <Text style={[styles.itemNameKit]}>
                                    {item.qty_on_hand}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          );
                        })}
                    </View>
                  </View>
                  <View style={{ marginTop: -35 }}>
                    <View style={{}}>
                      <Text
                        style={{
                          color: 'white',
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 18
                        }}
                      >
                        {i18n.t('product.kit_scanning')}
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      <View>
                        <TouchableOpacity
                          testID='DropDownButton'
                          onPress={() => this.dropDownToggle()}
                        >
                          <View style={[styles.mainContainer]}>
                            <View
                              style={[
                                styles.dropDownToggleStyle,
                                { flexDirection: 'row' }
                              ]}
                            >
                              <Text
                                style={{
                                  color: '#fff',
                                  fontFamily: 'Poppins_400Regular'
                                }}
                              >
                                {this.state.inventoryOption}
                              </Text>
                              <i
                                class="icon-down-open"
                                style={{ color: 'rgb(205,205,205)' }}
                              />
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>

                      <TouchableOpacity
                        testID='tooltipFun'
                        onPress={() => this.tooltipFun()}
                      >
                        <FontAwesome
                          name="info-circle"
                          style={{ marginTop: 5, marginLeft: 2 }}
                          size={17}
                          color="rgb(172,187,199)"
                        />
                      </TouchableOpacity>
                    </View>
                    {this.state.dropDownToggleState && (
                      <View
                        style={{
                          width: 352,
                          left: -8,
                          top: -2,
                          backgroundColor: 'rgba(15, 39, 58, 0.9)',
                          borderRadius: 12
                        }}
                      >
                        {this.state.pickerSelectedOptions.map(
                          (option, index) => {
                            return (
                              <View key={index}>
                                <TouchableOpacity
                                  testID='dropDownItem'
                                  onPress={() => {
                                    this.props.show === true
                                      ? this.permissionCheck()
                                      : this.updatePicker(option.value);
                                  }}
                                  style={{
                                    marginTop: 10,
                                    justifyContent: 'center',
                                    backgroundColor:
                                      index == inventoryDropDownIndex
                                        ? '#4b5f76'
                                        : ''
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 14,
                                      color: '#fff',
                                      marginLeft: 10
                                    }}
                                  >
                                    {option.label}
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            );
                          }
                        )}
                      </View>
                    )}
                    {this.state.quickScanToolTip3 && (
                      <LinearGradient
                        testID='quickScanToolTip'
                        locations={[0, 1]}
                        colors={['#8a9daf', '#d7e1e9']}
                        style={{
                          padding: 10,
                          borderRadius: 5,
                          width: 300,
                          zIndex: 99,
                          marginLeft: '7%'
                        }}
                      >
                        <Text style={{ fontSize: 20, color: '#030303' }}>
                          <Text style={{ fontFamily: 'Poppins_600SemiBold' }}>
                            {i18n.t('product.prePackedKitItem')}
                          </Text>{' '}
                            {i18n.t('product.oneBarcodeKitItem')}
                          <br />
                          <Text style={{ fontFamily: 'Poppins_600SemiBold' }}>
                            {i18n.t('product.kitParts')}
                          </Text>{' '}
                          {i18n.t('product.singleKitItem')}
                          <br />
                          <Text style={{ fontFamily: 'Poppins_600SemiBold' }}>
                            {i18n.t('product.kitPrePacked')}
                          </Text>{' '}
                            {i18n.t('product.singleKitBarcode')}
                        </Text>

                        <View
                          style={{
                            position: 'absolute',
                            right: '15%',
                            top: -10,
                            borderLeftWidth: 10,
                            borderLeftColor: 'transparent',
                            borderRightWidth: 10,
                            borderRightColor: 'transparent',
                            borderBottomWidth: 10,
                            borderBottomColor: '#8d9fb1'
                          }}
                        />
                      </LinearGradient>
                    )}
                  </View>
                </View>
              </>
            ) : (
              <>
                <View style={{ marginLeft: '25px', width: '70%', display: 'none' }}>
                  {basicinfo?.is_kit == 1 ? (
                    <Text style={[styles.KitHeading]}>
                      KIT: {this.props.product?.skus?.[0]?.sku}
                    </Text>
                  ) : (
                    <Text style={[styles.KitHeading]}>
                      {this.props.product?.skus?.[0]?.sku}
                    </Text>
                  )}
                </View>
                <View
                  style={[styles.inventoryTolltip]}
                  onStartShouldSetResponder={() =>
                    this.state.toolTipCheck ? this.tooltipFun() : <></>
                  }
                >
                  {productskit?.length >= 1 ? (
                    <View
                      style={{
                        marginTop: 7,
                        marginRight: 50,
                        marginBottom: -20
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          this.props.show === true
                            ? this.permissionCheck()
                            : this.setState({ itemadd: true });
                        }}
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          alignSelf: 'flex-end'
                        }}
                      >
                        <i
                          className="icon-plus"
                          style={{
                            color: 'white',
                            marginTop: '7%',
                            fontSize: '45px'
                          }}
                        ></i>
                        <Text
                          style={[styles.inventoryAddItemText, { marginLeft: 8, width: '100%' }]}
                        >
                          Add Items
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <></>
                  )}
                  <View
                    style={{
                      flex: 1,
                      marginHorizontal: 10,
                      backgroundColor: 'rgba(15, 39, 58, 0.4)',
                      borderRadius: 15,
                      marginBottom: 50,
                      marginTop: 40,
                      paddingVertical: 15,
                      paddingLeft: 10,
                      flexWrap: 'wrap'
                    }}
                  >
                    <View style={{ width: '100%' }}>
                      {!this.state.itemadd ? (
                        <View style={{ width: '100%' }}>
                          <View
                            style={{
                              margin: 120,
                              marginTop: 39,
                              marginLeft: 0,
                              flexDirection: 'row',
                              paddingHorizontal: '1%',
                              width: '96%',
                              marginTop: -3,
                              justifyContent: 'space-around',
                              flexWrap: 'wrap',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            {productskit &&
                              productskit.map((item, index) => {
                                return (
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      width: '100%',
                                      borderBottomWidth: 0.7,
                                      borderColor: 'rgba(250, 250, 250, .4)',
                                      marginTop: 18
                                    }}
                                  >
                                    <View
                                      style={{
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <TouchableOpacity
                                        onPress={() => {
                                          this.props.show === true
                                            ? this.permissionCheck()
                                            : this.removeKitItem(
                                              item.option_product_id
                                            );
                                          // this.props.userRole.role.add_edit_products ?
                                          //   this.removeKitItem(item.option_product_id) : <></>
                                          // this.removeItems();
                                        }}
                                        style={[
                                          styles.itemView,
                                          {
                                            width: '5%',
                                            marginRight:
                                              this.state.windowWidth < 600
                                                ? 20
                                                : 50
                                          }
                                        ]}
                                      >
                                        <i
                                          className="icon-cancel"
                                          style={{
                                            color: 'white',
                                            fontSize: '38px',
                                            marginLeft: -23
                                          }}
                                        ></i>
                                      </TouchableOpacity>
                                    </View>

                                    <View
                                      style={[
                                        styles.itemView,
                                        {
                                          width: '88%',
                                          flexDirection: 'column'
                                        }
                                      ]}
                                    >
                                      <View
                                        style={[
                                          styles.itemView,
                                          { width: '100%' }
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            styles.itemName,
                                            {
                                              color: '#aad9fc',
                                              fontSize: 19,
                                              marginBottom: 6
                                            }
                                          ]}
                                        >
                                          {item.name}
                                        </Text>
                                      </View>
                                      <View
                                        style={[
                                          styles.itemView,
                                          { width: '100%' }
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            styles.itemName,
                                            { color: 'white', fontSize: 19 }
                                          ]}
                                        >
                                          {item.sku}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: 'row',
                                          width: '100%'
                                        }}
                                      >
                                        <View>
                                          <Text
                                            style={{
                                              color: '#fff',
                                              fontSize: 19,
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            QTY/KIT
                                          </Text>
                                        </View>
                                        <View
                                          style={[
                                            styles.itemView,
                                            {
                                              flexDirection: 'row',
                                              marginLeft: 19
                                            }
                                          ]}
                                        >
                                          {this.state.editable == index ? (
                                            <TextInput
                                              placeholderTextColor="white"
                                              keyboardType="default"
                                              autoFocus={true}
                                              placeholder={item.qty}
                                              onChangeText={text =>
                                                this.handleChange(text, item)
                                              }
                                              onBlur={() => {
                                                {
                                                  item.qty > 0
                                                    ? (this.updateQuantity(
                                                      item
                                                    ),
                                                      this.setState({
                                                        editable: -1
                                                      }))
                                                    : (this.props.removeIds.includes(
                                                      item.option_product_id
                                                    ),
                                                      this.props.removeOrderItem(
                                                        item
                                                      ),
                                                      this.props.removeItems(),
                                                      this.setState({
                                                        editable: -1
                                                      }));
                                                }
                                              }}
                                              style={{
                                                width: 25,
                                                height: 25,
                                                fontSize: 19,
                                                color: 'white'
                                              }}
                                            />
                                          ) : (
                                            <Text
                                              style={
                                                (styles.itemName,
                                                {
                                                  color: 'white',
                                                  fontSize: 19
                                                })
                                              }
                                            >
                                              {item.qty}
                                            </Text>
                                          )}
                                          <TouchableOpacity
                                            onPress={() => {
                                              this.props.show === true
                                                ? this.permissionCheck()
                                                : this.setState({
                                                  editable: index
                                                });
                                              // this.props.userRole.role.add_edit_products ?
                                              //   this.setState({ editable: index }) : <></>
                                              // this.changeEdit();
                                            }}
                                            style={{ marginTop: 2 }}
                                          >
                                            <i
                                              className="icon-edit"
                                              style={{
                                                color: 'white',
                                                fontSize: '20px'
                                              }}
                                            ></i>
                                          </TouchableOpacity>
                                        </View>
                                      </View>
                                      <View
                                        style={[
                                          styles.itemView,
                                          { width: '100%' }
                                        ]}
                                      >
                                        {item.product_status === 'active' ? (
                                          <Text
                                            style={{
                                              fontSize: 19,
                                              color: '#7bfac3',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {this.Capitalize(
                                              item.product_status
                                            )}
                                          </Text>
                                        ) : (
                                          <Text
                                            style={{
                                              fontSize: 19,
                                              color: '#eb53fa',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {this.Capitalize(
                                              item.product_status
                                            )}
                                          </Text>
                                        )}
                                      </View>
                                    </View>
                                    <View
                                      style={[
                                        styles.itemViewKit,
                                        {
                                          position: 'absolute',
                                          bottom: 0,
                                          right: 0
                                        }
                                      ]}
                                    >
                                      <Image
                                        style={{
                                          width: 75,
                                          height: 75,
                                          borderRadius: 7
                                        }}
                                        source={{ uri: item?.images[0]?.image }}
                                      />
                                    </View>
                                  </View>
                                );
                              })}
                          </View>
                          {productskit?.length == 0 ? (
                            <View
                              style={{
                                marginTop: -10,
                                alignSelf: 'center'
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  this.props.show === true
                                    ? this.permissionCheck()
                                    : this.setState({ itemadd: true });
                                }}
                                style={{
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginTop: -65
                                }}
                              >
                                <i
                                  className="icon-plus"
                                  style={{
                                    color: 'white',
                                    marginTop: '7%',
                                    fontSize: '32px'
                                  }}
                                ></i>
                                <Text
                                  style={[styles.inventoryAddItemText]}
                                >
                                  Add Items
                                </Text>
                              </TouchableOpacity>
                            </View>
                          ) : (
                            <></>
                          )}
                          <View></View>
                        </View>
                      ) : (
                        <View>
                          <AddProduct
                            onchange={() =>
                              this.setState({ itemadd: !this.state.itemadd })
                            }
                            AddItemToInventory={() => this.AddItemToInventory()}
                            hideSaveFun={() => this.props.hideSaveFun()}
                            productskit={productskit}
                            {...this.props}
                          />
                        </View>
                      )}
                    </View>
                  </View>
                  <View style={{ marginTop: -20, marginLeft: 3 }}>
                    <View>
                      <Text
                        style={{
                          color: 'white',
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 18
                        }}
                      >
                        {i18n.t('product.kit_scanning')}
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      <View>
                        <TouchableOpacity onPress={() => this.dropDownToggle()}>
                          <View style={[styles.mainContainer]}>
                            <View
                              style={[
                                styles.dropDownToggleStyle,
                                { flexDirection: 'row' }
                              ]}
                            >
                              <Text
                                style={{
                                  color: '#fff',
                                  fontFamily: 'Poppins_400Regular'
                                }}
                              >
                                {this.state.inventoryOption}
                              </Text>
                              <i
                                class="icon-down-open"
                                style={{ color: 'rgb(205,205,205)' }}
                              />
                            </View>
                          </View>
                        </TouchableOpacity>
                        {this.state.dropDownToggleState && (
                          <View
                            style={{
                              position: 'absolute',
                              top: -90,
                              zIndex: 1,
                              width: 341,
                              left: -3,
                              backgroundColor: 'rgba(15, 39, 58, 0.9)',
                              borderRadius: 12
                            }}
                          >
                            {this.state.pickerSelectedOptions.map(
                              (option, index) => {
                                return (
                                  <View key={index}>
                                    <TouchableOpacity
                                      onPress={() => {
                                        this.props.show === true
                                          ? this.permissionCheck()
                                          : this.updatePicker(option.value);
                                      }}
                                      style={{
                                        marginTop: 10,
                                        justifyContent: 'center',
                                        backgroundColor:
                                          index == inventoryDropDownIndex
                                            ? '#4b5f76'
                                            : ''
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontFamily: 'Poppins_400Regular',
                                          fontSize: 14,
                                          color: '#fff',
                                          marginLeft: 10
                                        }}
                                      >
                                        {option.label}
                                      </Text>
                                    </TouchableOpacity>
                                  </View>
                                );
                              }
                            )}
                          </View>
                        )}
                      </View>
                      <TouchableOpacity onPress={() => this.tooltipFun()}>
                        <FontAwesome
                          name="info-circle"
                          size={17}
                          style={{ marginTop: 2, marginLeft: '38%' }}
                          color="rgb(172,187,199)"
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View>
                    {this.state.quickScanToolTip3 && (
                      <LinearGradient
                        locations={[0, 1]}
                        colors={['#8a9daf', '#d7e1e9']}
                        style={{
                          padding: 10,
                          position: 'relative',
                          left: '15%',
                          borderRadius: 5,
                          width: 320
                        }}
                      >
                        <Text style={{ fontSize: 12, color: '#030303' }}>
                          {i18n.t('order.inventoryKitTollTip')}
                        </Text>
                        <View
                          style={{
                            position: 'absolute',
                            right: '6%',
                            top: -10,
                            borderLeftWidth: 10,
                            borderLeftColor: 'transparent',
                            borderRightWidth: 10,
                            borderRightColor: 'transparent',
                            borderBottomWidth: 10,
                            borderBottomColor: '#8d9fb1'
                          }}
                        />
                      </LinearGradient>
                    )}
                  </View>
                </View>
              </>
            )}
            {productskit <= 1 && this.state.windowWidth > 900 && (
              <View
                style={{
                  marginTop: 70,
                  alignSelf: 'center'
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    this.props.show === true
                      ? this.permissionCheck()
                      : this.setState({ itemadd: true });
                  }}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <i
                    className="icon-plus"
                    style={{ color: 'white', fontSize: '70px' }}
                  ></i>
                  <Text
                    style={[styles.inventoryAddItemText]}
                  >
                    Add Items
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </>
        ) : (
          <>
            <View>
              <AddProduct
                onchange={() => this.setState({ itemadd: !this.state.itemadd })}
                AddItemToInventory={() => this.AddItemToInventory()}
                hideSaveFun={() => this.props.hideSaveFun()}
                productskit={productskit}
                {...this.props}
                upDateState = {(value)=>{this.setState({lastSearch: value})}}
                lastSearched={this.state.lastSearch}
              />
            </View>
          </>
        )
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    allProduct: state.product.allProduct,
    productskit: state.product
  };
};

const mapDispatchToProps = {
  GETAllKITProduct,
  GetProductDetail,
  RemoveKITProduct,
  updateProductList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryKitOptions);
