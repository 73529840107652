import React, {useEffect, useState} from 'react';
import {View, StyleSheet, Dimensions} from 'react-native';
import DraggableFlatList, {
  ScaleDecorator
} from 'react-native-draggable-flatlist';
import {OrderTagPriorityCard} from './orderTagPriorityCard';
import {useDispatch} from 'react-redux';
import {
  GetTagPriorityList,
  handleCombineUpdate,
  UpdateTagPriority,
  UpdateTagPriorityPositions
} from '../../../actions/tagPriorityActions';
import CustomScrollBar from '../../../commons/customScrollBar';
import {handleDataUpdate} from './helperFunctions';

export const DraggableList = ({tagPriorities, onEdit, lastIndex = null}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(tagPriorities);
  const [screenHeight, setScreenHeight] = useState(
    Dimensions.get('window').height
  );

  useEffect(() => {
    const handleResize = () => {
      const {height} = Dimensions.get('window');
      setScreenHeight(height);
    };

    Dimensions.addEventListener('change', handleResize);
    return () => {
      Dimensions.removeEventListener('change', handleResize);
    };
  }, []);
  const factor = screenHeight > 1100 ? 0.81 : 0.782
  const containerHeight = screenHeight * factor;
  useEffect(() => {
    setData(tagPriorities);
  }, [tagPriorities]);

  const updateItemProperty = (item, propertyName, newValue, isDisabledUpdate = false) => {
    const updatedData = data.map(tag =>
      tag.id === item.id ? {...tag, [propertyName]: newValue} : tag
    );
    setData(updatedData);

    const payload = {
      priority_card: {
        ...item,
        [propertyName]: newValue
      }
    };
    dispatch(UpdateTagPriority(item.id, payload, isDisabledUpdate && handleUpdateCallBack));
  };
  const handleUpdateCallBack = () => {
    dispatch(GetTagPriorityList())
  };

  const handleDisAble = item => {
    const newValue = !item.is_card_disabled;
    updateItemProperty(item, 'is_card_disabled', newValue, true);
  };

  const handleFavorite = item => {
    const newValue = !item.is_favourite;
    updateItemProperty(item, 'is_favourite', newValue);
  };

  const handleDragEnd = ({data: newData}) => {
    const updates = handleDataUpdate(newData, data, lastIndex);
    setData(newData);
    if (updates.attributeUpdate) {
      dispatch(
        handleCombineUpdate(updates.attributeUpdate, updates.positionUpdateData)
      );
    } else {
      dispatch(UpdateTagPriorityPositions(updates.positionUpdateData));
    }
  };
  const renderItem = ({item, drag, isActive}) => {
    return (
      <ScaleDecorator>
        <OrderTagPriorityCard
          tagPriority={item}
          onPress={drag}
          isActive={isActive}
          onEdit={() => onEdit(item)}
          handleDisAble={() => handleDisAble(item)}
          handleFavorite={() => handleFavorite(item)}
        />
      </ScaleDecorator>
    );
  };

  return (
    <View style={[styles.container, {maxHeight: containerHeight}]}>
      <CustomScrollBar>
        <DraggableFlatList
          data={data}
          keyExtractor={item => item.id.toString()}
          onDragEnd={handleDragEnd}
          renderItem={renderItem}
        />
      </CustomScrollBar>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
});
