import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import i18n from 'i18n-js';
import * as Localization from 'expo-localization';

export const useLanguageLoader = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentTenant, setCurrentTenant] = useState('');

  const loadLanguage = async () => {
    try {
      const tenant = await AsyncStorage.getItem('tenent');
      const id = await AsyncStorage.getItem('userId');
      const tenantKey = tenant;
      setCurrentTenant(tenantKey);

      const savedLanguage = await AsyncStorage.getItem(`language_${tenantKey}_${id}`);
      if (savedLanguage) {
        i18n.locale = savedLanguage;
      } else {
        i18n.locale = Localization.locale || 'en';
      }
    } catch (error) {
      console.error('Error loading language or tenant:', error);
      i18n.locale = 'en';
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadLanguage();
  }, []);

  return { isLoading, currentTenant };
};
