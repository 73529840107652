import React, {useEffect, useState, useRef} from 'react';
import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import CommonStyles from '../../style/commonStyles';
import CustomScrollBar from '../../commons/customScrollBar';
import I18n from 'i18n-js';

const CustomTouchableOpacity = ({
  onPress,
  selectedStatus,
  handleStatusSelect,
  disableFilter,
  buttonName,
  buttonText,
  orderCount,
  testID,
  userAddedCount
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={styles.customTouchable}
      testID={testID}>
      <View style={CommonStyles.customTouchableContent}>
        <Text style={CommonStyles.customTouchableCount}>{orderCount}</Text>
        <Text
          style={[
            handleStatusSelect?.includes(buttonName) &&
              styles.dropdownActiveButton,
            CommonStyles.customTouchableText
          ]}>
          {`${buttonText || buttonName} ${userAddedCount?.find(item => item.user === buttonName)?.assignedOrders ? '+ ' + userAddedCount.find(item => item.user === buttonName).assignedOrders : ''}`}
        </Text>
        <TouchableOpacity
          onPress={disableFilter}
          style={CommonStyles.customTouchableReset}
          testID={testID + '_reset'}>
          {handleStatusSelect.includes(buttonName) && (
            <i className="icon-cancel" style={CommonStyles.customTouchableIcon}></i>
          )}
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};

const SaveChangeButton = ({onPress, testID}) => (
  <TouchableOpacity
    style={[
      CommonStyles.saveButton,
      styles.saveChangeButton,
    ]}
    onPress={onPress}
    >
    <Text style={CommonStyles.saveButtonText}>Assign Orders</Text>
  </TouchableOpacity>
);

const OrderUserTagsDropdown = ({
  userTags,
  clearAllFilters,
  resetFilterCheck,
  status,
  setSelectedTags,
  setFilterUserTags,
  setPrompt,
  assignOrdersToUsers,
  userAddedCount
}) => {
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(() => status);

  const toggleDropdown = () => setIsOpen(prev => !prev);

  const handleStatusSelect = status => {
    let statues;
    let isPresent = false;
    setSelectedStatus(prevSelectedStatus => {
      if (!prevSelectedStatus.includes(status)) {
        statues = [...prevSelectedStatus, status];
        return [...prevSelectedStatus, status];
      } else {
        statues = [prevSelectedStatus];
        isPresent = true;
        return prevSelectedStatus;
      }
    });
    isPresent === true && disableFilter(status);
  };

  const disableFilter = statusToRemove => {
    let updatedStatus = [];
    setSelectedStatus(prevSelectedStatus => {
      updatedStatus = prevSelectedStatus.filter(
        status => status !== statusToRemove
      );
      return updatedStatus;
    });
  };

  useEffect(() => {
    if (clearAllFilters === true) {
      setSelectedStatus([]);
      resetFilterCheck();
    }
  }, [clearAllFilters]);

  useEffect(() => {
    if (selectedStatus) {
      setSelectedTags(selectedStatus);
    }
  }, [selectedStatus]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <View ref={dropdownRef}>
      <TouchableOpacity
        style={styles.button}
        testID="toggleButton"
        onPress={toggleDropdown}>
        <View style={CommonStyles.buttonContent}>
          <Text style={{fontFamily: 'Poppins_600SemiBold', color: '#7a7a7a'}}>
            User Assignment
          </Text>
          <i
            className={`${!isOpen ? 'icon-down-open' : 'icon-up-open'}`}
            style={{color: '#7A7A7A', fontSize: 15}}></i>
        </View>
      </TouchableOpacity>
      {isOpen && (
        <>
          <View style={styles.dropDownContainer}>
            {selectedStatus?.length === 0 && (
              <TouchableOpacity
                testID="clearAllAssignment"
                onPress={() => setPrompt(true)}
                style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <Text style={[styles.text, styles.clearUserTag]}>
                  {I18n.t("order.clearAllAssignmentLabel")}
                </Text>
              </TouchableOpacity>
            )}
            {selectedStatus.length > 0 && (
              <TouchableOpacity
                testID="clearAssignment"
                onPress={() => assignOrdersToUsers(selectedStatus, false)}
                style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <Text style={[CommonStyles.text, styles.clearUserTag]}>
                  Clear User Assignment
                </Text>
              </TouchableOpacity>
            )}
            {selectedStatus?.length >= 1 && (
              <SaveChangeButton
                onPress={() => assignOrdersToUsers(selectedStatus, true)}
                testID={'save_user_tags_button'}
              />
            )}
            <CustomScrollBar>
              <View style={styles.dropdownContent}>
                {userTags?.map(tag => (
                  <CustomTouchableOpacity
                    key={tag.username} // Use tag.username for a unique key
                    onPress={() => {
                      setFilterUserTags(true);
                      handleStatusSelect(tag.username); // Select based on username
                    }}
                    selectedStatus={selectedStatus}
                    handleStatusSelect={selectedStatus}
                    disableFilter={() => disableFilter(tag.username)}
                    buttonName={tag.username} // Display username as button name
                    buttonText={tag.username} // Display unique order count in button text
                    orderCount={tag.unique_order_count} // Pass unique_order_count
                    testID={`${tag.username.toLowerCase()}_button`} // Test ID based on username
                    userAddedCount={userAddedCount}
                  />
                ))}
              </View>
            </CustomScrollBar>
          </View>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  dropdownActiveButton: {
    border: '1px solid gray',
    borderRadius: 8,
    paddingTop: 2
  },
  button: {
    maxWidth: '107%',
    backgroundColor: '#262B30',
    marginLeft: 2
  },
  saveChangeButton: {
    borderWidth: 2,
    shadowColor: '#E853FA',
    shadowOffset: {width: 0, height: 0.5},
    shadowRadius: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingVertical: 5,
    paddingHorizontal: 7
  },
  saveChangeButtonText: {
    textAlign: 'center',
    color: 'white',
    padding: 5
  },
  dropdownContent: {
    marginTop: 10
  },
  clearUserTag: {
    color: '#A97627'
  },
  dropDownContainer: {
    position: 'absolute',
    top: '80%',
    left: 0,
    right: 0,
    borderRadius: 5,
    marginTop: 5,
    width: '15rem',
    maxHeight: '50vh',
    minWidth: '9vw',
    backgroundColor: 'rgb(38, 43, 48)',
    padding: 1
  }
});

export default OrderUserTagsDropdown;
