import React from "react";
import { View, TouchableOpacity, Text, Image, StyleSheet, Vibration } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { fontFamily } from "../helpers/fontFamily";
import placeholder from "../../assets/placeholder.jpg";
import { useSelector } from "react-redux";
import { collectManifestSchemes } from "expo-linking";

const OrderItemImage = ({ item, shuffleTheItem, testID, listType }) => {
  const isHaptic = useSelector((state) => state.menu.isHaptics);
  const totalQty = item?.total_qty === undefined || item.total_qty < item.qty_remaining + item.scanned_qty ? 
  item.qty_remaining + item.scanned_qty : item.total_qty  
  return (
    <TouchableOpacity
      key={item?.id}
      testID={testID}
      onPress={() => {
        isHaptic && Vibration.vibrate(30)
        shuffleTheItem(item.sku);
      }}
    >
      <View style={styles.container}>
        <LinearGradient
          locations={[0, 0.04, 0.08]}
          start={{ x: 0.9, y: 1 }}
          colors={["#3d4851", "#9EA4A8", "#ffffff"]}
          style={styles.gradientContainer}
        >
          <Text style={styles.gradientText} dataSet={{ componentName: 'productUnscannedKitCount'}}>
            {/* {item.qty_remaining}/{item?.total_qty === undefined || item.total_qty < item.qty_remaining + item.scanned_qty ? 
              item.qty_remaining + item.scanned_qty : item.total_qty} */}
            {(listType === 'unscanned') ? `${item.qty_remaining}/${totalQty}` : `${item.scanned_qty}/${totalQty} `  }
          </Text>

          {item?.images[0]?.image ? (
            <Image
              defaultSource={placeholder}
              source={{ uri: item?.images[0]?.image }}
              style={styles.imageContainer}
            />
          ) : (
            <Image source={placeholder} style={styles.imageContainer} />
          )}
        </LinearGradient>
      </View>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
  },
  gradientContainer: {
    height: 118,
    width: 100,
    backgroundColor: "#000000",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.58,
    shadowRadius: 29,
    elevation: 3,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    marginTop: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  gradientText: {
    width: "100%",
    position: "absolute",
    textAlign: "center",
    fontSize: 18,
    fontFamily: fontFamily.font600,
    backgroundColor: "#fff",
    opacity: 0.7,
    bottom: 0,
    right: 0,
    left: 0,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 999,
  },
  imageContainer: {
    height: "100%",
    width: "96%",
    resizeMode: "cover",
    borderRadius: 10,
  },
});
export default OrderItemImage;