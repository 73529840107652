import React from 'react';
import {Text, View, StyleSheet} from 'react-native';
import {FilterMenu} from '../filterMenu';

export const PriorityListToggle = ({
  count,
  onPress,
  isListDisplayed,
  selectedMenuId
}) => {
  return (
    <View style={styles.container}>
      <FilterMenu selected={selectedMenuId} onPress={id => onPress(id)} />
      {selectedMenuId < 2 && (
        <Text
          style={[
            styles.orderText,
            {
              color: count > 0 ? '#45D798' : '#9BA7B4',
              fontWeight: isListDisplayed ? 'bold' : 'normal'
            }
          ]}>
          {`Orders selected ${count}`}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1%',
    width: 280,
  },
  orderText: {
    alignSelf: 'center',
    fontSize: 14,
    marginTop: 10
  }
});
