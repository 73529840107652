import React from 'react';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {FontAwesome} from '@expo/vector-icons';
import {lightenColor} from '../../../helpers/getLighterColor';
import moment from 'moment';
import {AddTagButton} from './addTagButton';

export const OrderTagPriorityCard = ({
  tagPriority,
  onPress,
  isActive,
  onEdit,
  handleDisAble,
  handleFavorite,
}) => {
  const isDisabled = tagPriority?.is_card_disabled ?? false;
  const lighterColor = lightenColor(tagPriority?.tag_color || '#000');
  const containerStyle = isActive
    ? [styles.orderSection, styles.activeOrderSection]
    : styles.orderSection;
  const isRegularType = tagPriority?.priority_name === 'regular';
  const timeAgo = tagPriority?.oldest_order
    ? moment(tagPriority.oldest_order).fromNow()
    : '';
  const isFavorite = tagPriority?.is_favourite ?? false;

  const isUserCard = tagPriority?.is_user_card ?? false;
  let iconName = isUserCard ? 'icon-user-circle-o' : 'icon-receive';

  if (tagPriority?.addButton) {
    return (
      <AddTagButton
        title={isUserCard ? 'Add user card' : 'Add a Priority'}
      />
    );
  }

  return (
    <View
      style={[
        styles.cardContainer,
        {backgroundColor: isDisabled && '#1E2329'}
      ]}>
      <LinearGradient
        colors={[lighterColor, tagPriority.tag_color]}
        start={{x: 0, y: 0}}
        end={{x: 0, y: 1}}
        style={styles.verticalLine}
      />
      {/* Main content */}
      <View style={styles.contentContainer}>
        {/* Header section */}
        <View style={styles.header}>
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            style={styles.headerText}
          >
            {tagPriority.priority_name}
          </Text>
          <View style={styles.headerIcons}>
            {!isRegularType && (
              <TouchableOpacity
                testID={`updatePriority_${tagPriority.priority_name}`}
                style={styles.iconButton}
                onPress={() => {
                  onEdit(tagPriority);
                }}>
                <FontAwesome name="edit" style={styles.editIcon} />
              </TouchableOpacity>
            )}
            <TouchableOpacity
              testID={`disablePriority_${tagPriority.priority_name}`}
              style={styles.iconButton}
              onPress={() => {
                handleDisAble(tagPriority);
              }}>
              <i
                className={isDisabled ? 'icon-check-empty' : 'icon-check'}
                style={styles.checkIcon}></i>
            </TouchableOpacity>
          </View>
        </View>

        {/* Order count and main icons */}
        <TouchableOpacity style={containerStyle} onPressIn={onPress}>
          <FontAwesome name="bars" style={styles.menuIcon} />

          <Text style={styles.orderCount}>
            {tagPriority.order_tagged_count}
          </Text>
          <View style={styles.orderIcons}>
            {isRegularType || isUserCard ? (
              <i className={iconName} style={styles.regularIcon}></i>
            ) : (
              <FontAwesome
                name={tagPriority.is_stand_by ? "clock-o" : "star"}
                size={40}
                color="#575E66"
                style={styles.iconSpacing}
              />
            )}
          </View>
        </TouchableOpacity>
        <View style={styles.footer}>
          <Text style={styles.footerText}>Oldest order: {timeAgo}</Text>
          <View style={styles.footerIcons}>
            <FontAwesome
              name="bolt"
              size={20}
              color="white"
              style={[styles.iconSpacing, {marginRight: isRegularType && 10}]}
            />
            {!isRegularType && (
              <TouchableOpacity
                testID={`favoritePriority_${tagPriority.priority_name}`}
                onPress={() => {
                  handleFavorite(tagPriority);
                }}>
                <FontAwesome
                  name="heart"
                  size={20}
                  color={isFavorite ? 'white' : '#575E66'}
                  style={[styles.iconSpacing, {marginRight: 6}]}
                />
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: '98%',
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 2},
    shadowRadius: 6,
    elevation: 5,
    marginVertical: 10,
    borderColor: '#ddd',
    borderWidth: 0.5,
    position: 'relative',
    paddingLeft: 10
  },
  verticalLine: {
    position: 'absolute',
    top: 7,
    bottom: 0,
    left: 0,
    width: 6,
    height: '89%',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  contentContainer: {
    flex: 1,
    borderRadius: 10,
    paddingVertical: 10
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerText: {
    color: '#C7C7C7',
    fontSize: 16,
    fontWeight: '600'
  },
  headerIcons: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconButton: {
    marginLeft: 5
  },
  orderSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  orderCount: {
    color: 'white',
    fontWeight: '700',
    fontSize: 40
  },
  orderIcons: {
    alignItems: 'center'
  },
  iconSpacing: {
    marginLeft: 13,
    marginRight: 8
  },
  regularIcon: {
    marginLeft: 13,
    fontSize: 40,
    color: '#575E66'
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  footerText: {
    color: '#C7C7C7',
    fontSize: 12
  },
  footerIcons: {
    flexDirection: 'row'
  },
  editIcon: {
    fontSize: 20,
    color: 'white',
    marginRight: 10
  },
  checkIcon: {
    fontSize: 20,
    color: 'white',
    marginRight: 5
  },
  menuIcon: {
    fontSize: 40,
    color: '#575E66'
  },
  activeOrderSection: {
    backgroundColor: '#192736'
  }
});
