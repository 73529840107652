import React from 'react';
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';

import CommonStyles from '../../../style/commonStyles';

export const DisableFiltersButton = ({
  isFiltersActive,
  onPress,
}) => {
  return (
    <TouchableOpacity
      testID="disableFiltersButton"
      style={[
        styles.button,
        {
          borderColor: isFiltersActive ? '#fff' : 'gray',
        },
      ]}
      onPress={onPress}
    >
      <View style={styles.content}>
        <Text
          style={[
            CommonStyles.saveButtonText,
            styles.text,
            {
              color: isFiltersActive ? 'white' : 'gray',
            },
          ]}
        >
          {isFiltersActive ? 'Clear All Filters' : 'Filters disabled'}
        </Text>
        <FontAwesome
          name="bomb"
          size={12}
          color={isFiltersActive ? 'white' : 'gray'}
          style={styles.icon}
        />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    marginRight: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    height: 18,
    fontFamily: 'Poppins_300Light',
    marginTop: 10,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontSize: 12,
  },
  icon: {
    marginRight: 4,
  },
});
