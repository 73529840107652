import React, { useEffect, useState, useRef } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet, TextInput } from 'react-native';
import downArrow from '../../../assets/down_arrow.png';
import './custom_date_picker.css'
import DatePicker from '../../commons/datePicker';
import usePersistentMenuState from '../../commons/usePersistentMenuState';

const handleCancelPress = (setSearchTxt, disableFilter) => {
  setSearchTxt("");
  disableFilter();
};

let showInput = true;

const CustomTouchableOpacity = ({
  onPress,
  selectedStatus,
  handleStatusSelect,
  disableFilter,
  buttonName,
  buttonText,
  getFilteredOrders,
  testID,
  showInput
}) => {
  const [searchTxt, setSearchTxt] = useState('');

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{paddingLeft: 5}}
      testID={testID}>
      <View style={styles.touchAbleButtonContainer}>
        <Text
          style={[
            handleStatusSelect?.includes(buttonName) &&
              styles.dropdownActiveButton,
            styles.buttonLabel,
            {
              color: handleStatusSelect?.includes(buttonName)
                ? '#45d697'
                : 'white'
            }
          ]}>
          {buttonText || buttonName}
        </Text>
        <TouchableOpacity
          onPress={disableFilter}
          style={[{marginHorizontal: '1%'}]}
          testID={testID + '_reset'}>
          {handleStatusSelect.includes(selectedStatus) && (
            <i
              className="icon-cancel"
              style={{fontSize: '15px', color: 'gray'}}></i>
          )}
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};

const CustomTouchableFieldOpacity = ({
  onPress,
  selectedStatus,
  handleStatusSelect,
  disableFilter,
  buttonName,
  buttonText,
  getFilteredOrders,
  testID,
  showInput,
  searchTxt,
  setSearchTxt
}) => {

  let isEnterPressed = false;

  useEffect(() => {
      if( handleStatusSelect?.includes('all_day'))
      {
        // setSearchTxt('');
      }
  }, [handleStatusSelect]);

  return (
    <View testID={testID}>
      <View style={styles.customFieldContainer}>
        <View
          style={[
            styles.customFiledSubContainer,
            {
              borderColor: handleStatusSelect?.includes(searchTxt) ? 'gray' : 'transparent',
              borderWidth: searchTxt ? 1 : 0
            }
          ]}>
          <Text
            style={[
              handleStatusSelect?.includes(buttonName) &&
                styles.dropdownActiveButton,
              styles.customFiledText,
              {
                color: searchTxt ? 'rgb(69, 214, 151)' : 'white',
                width: '17%',
                marginLeft: '13%'
              }
            ]}>
            Last
          </Text>

          {showInput && (
            <TextInput
              testID="DateNumberInput"
              name="searchWithDays"
              value={searchTxt}
              onChangeText={text => {
                setSearchTxt(text);
              }}
              onSubmitEditing={() => {}}
              style={[
                styles.customFiledInput,
                {
                  color: searchTxt ? 'rgb(69, 214, 151)' : 'gray'
                }
              ]}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  onPress(searchTxt);
                  isEnterPressed = true;
                }
              }}
            />
          )}
          <Text
            style={[
              handleStatusSelect?.includes(buttonName) &&
                styles.dropdownActiveButton,
                styles.customFiledText,
              {
                color: searchTxt ? 'rgb(69, 214, 151)' : 'white',
                width: '65%',
                marginLeft: '1%',
              }
            ]}>
            days
          </Text>
        </View>

        <TouchableOpacity
          onPress={() =>
            handleCancelPress(setSearchTxt, disableFilter)
          }
          style={[{marginHorizontal: '1%'}]}
          testID={testID + '_reset'}>
          {handleStatusSelect?.includes(searchTxt) && (
            <i
              className="icon-cancel"
              style={{fontSize: '15px', color: 'gray'}}></i>
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};


const DateFilterButtons = ({
  selectedStatus,
  handleStatusSelect,
  handleCancelPress,
  setSearchTxt,
  disableFilter,
  getFilteredOrders
}) => {
  const dateFilterButtons = [
    { id: 'all_day', text: 'All Dates' },
    { id: 'yesterday', text: 'Yesterday' },
    { id: 'today', text: 'Today' },
    { id: 'this_week', text: 'This Week' },
    { id: 'last_week', text: 'Last Week' },
    { id: 'this_month', text: 'This Month' },
    { id: 'last_month', text: 'Last Month' }
  ];
  return (
    <>
      {dateFilterButtons.map(({ id, text }) => (
        <CustomTouchableOpacity
          key={id}
          onPress={() => {
            handleCancelPress(setSearchTxt, disableFilter);
            handleStatusSelect(id);
          }}
          selectedStatus={id}
          handleStatusSelect={selectedStatus}
          disableFilter={() => disableFilter(id)}
          buttonName={id}
          buttonText={text}
          getFilteredOrders={getFilteredOrders}
          testID={`${id}_button`}
        />
      ))}
    </>
  );
};

const OrderDateDropdown = ({getFilteredOrders, clearAllFilters, resetFilterCheck, status}) => {
  const isNumber = !isNaN(Number(status));
  const [isOpen, toggleDropdown] = usePersistentMenuState('dateDropdown');
  const [selectedStatus, setSelectedStatus] = useState(() => status);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchTxt, setSearchTxt] = useState(isNumber? status[0] : "");
  const startPickerRef = useRef(null);
  const endPickerRef = useRef(null);


  useEffect(() => {
      if( selectedStatus?.includes('all_day') && startDate && endDate)
      {
        clearDates();
      }
  }, [status]);

  const handleStatusSelect = (status, date = "") => {
    let statues;
    statues = status === 'start_date' ? status : statues
    statues = status === 'end_date' ? status : statues
    let isPresent = false;
    setSelectedStatus(prevSelectedStatus => {
      if (!prevSelectedStatus.includes(status)) {
        statues = [status];
        return [status];
    } else {
        statues = [prevSelectedStatus];
        isPresent = true;
        return prevSelectedStatus;
    }
    });
    if (status !== 'start_date' && status !== 'end_date') {
      clearDates();
    }
    isPresent === true ? disableFilter(status) : getFilteredOrders([statues], date)
    getFilteredOrders([status]);
  };

  const clearDates = () => {
    setEndDate(new Date());
    setStartDate(new Date());
    setTimeout(() => {
      setEndDate();
      setStartDate();
    }, 100);
  }

  const disableFilter = (statusToRemove) => {
    let updatedStatus = [];
    setSelectedStatus(prevSelectedStatus => {
        updatedStatus = prevSelectedStatus.filter(status => status !== statusToRemove);
        return updatedStatus;
    });
    getFilteredOrders(updatedStatus);
  };

  useEffect(() => {
      if(clearAllFilters === true)
      {
        setSelectedStatus([]);
        resetFilterCheck();
      }
  }, [clearAllFilters]);

  useEffect(() => {
      setSelectedStatus(status)
  }, [status]);

  return (
    <View style={{borderTop: '2px solid rgb(0, 0, 0)', top: 10}}>
      <TouchableOpacity style={styles.dateRangeButton} onPress={toggleDropdown}>
        <View style={styles.dateRangeMainContainer}>
          <Text style={styles.dateRangeText}>Date Range</Text>
          <Image style={[styles.dateRangeImage, isOpen && styles.iconOpen]} source={downArrow} />
        </View>
      </TouchableOpacity>
      {isOpen && (
        <View style={{marginTop: 10}}>
          <DateFilterButtons
            selectedStatus={selectedStatus}
            handleStatusSelect={handleStatusSelect}
            handleCancelPress={handleCancelPress}
            setSearchTxt={setSearchTxt}
            disableFilter={disableFilter}
            getFilteredOrders={getFilteredOrders}
          />
          <CustomTouchableFieldOpacity
            onPress={value => {
              handleStatusSelect(value);
              clearDates();
            }}
            selectedStatus={'x_days'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {
              disableFilter('x_days');
            }}
            buttonName="x_days"
            buttonText="Last Month"
            getFilteredOrders={() => {
              getFilteredOrders('x_days');
            }}
            testID="x_days_button"
            showInput={true}
            searchTxt={searchTxt}
            setSearchTxt={text => setSearchTxt(text)}
          />
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <View>
              <Text style={styles.datePickerText}>Choose a Range</Text>
            </View>
            <DatePicker
             startDate={startDate}
             endDate={endDate}
             setStartDate={(date)=>{
              setStartDate(date);
              setSearchTxt('');
              handleStatusSelect('start_date', date);
             }}
             setEndDate={(date)=>{
              if (date === null) {
                date = '';
              }
              setEndDate(date);
              setSearchTxt('');
              handleStatusSelect('end_date', date);
             }}
             startPickerRef={startPickerRef}
             endPickerRef={endPickerRef}
            />
          </View>
        </View>
      )}
    </View>
  );
};


const styles = StyleSheet.create({
  dropdownActiveButton: {
    border: '1px solid gray',
    borderRadius: 8,
    paddingTop: 2,
    color: 'green'
  },
  datePickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    marginRight: '12px'
  },
  datePickerText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
    color: 'white'
  },
  customFieldContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  customFiledSubContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 5,
    borderStyle: 'solid'
  },
  customFiledText: {
    paddingBottom: 2,
    fontSize: '15px',
    paddingLeft: 10,
    fontFamily: 'Poppins_300Light',
    marginBottom: '2px'
  },
  customFiledInput: {
    width: '25%',
    borderRadius: '10px',
    borderColor: 'gray',
    borderWidth: 0.5,
    height: 23,
    paddingLeft: '7px',
    backgroundColor: '#262b30',
    marginLeft: '3%'
  },
  dateRangeText: {
    color: '#9BA7B4',
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 13,
    padding: 5,
    marginLeft: 10
  },
  dateRangeImage: {
    width: 15,
    height: 15,
    marginRight: '4%',
    marginTop: '1.5%',
    color: 'gray'
  },
  iconOpen: {
    transform: [{rotate: '180deg'}]
  },
  dateRangeButton: {
    maxWidth: '107%',
    backgroundColor: '#262B30',
    marginLeft: '-13px'
  },
  touchAbleButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems:'baseline'
  },
  buttonLabel: {
    paddingBottom: 2,
    fontSize: '15px',
    paddingLeft: 10,
    width: '65%',
    marginLeft: '1%',
    fontFamily: 'Poppins_300Light',
    marginBottom: '2px'
  },
  dateRangeMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#262b30'
  }

});


export default OrderDateDropdown;
