export const includedTagData = [
  {name: 'Awaiting', text: 'Awaiting', order_count: 0},
  {name: 'PartiallyScanned', text: 'Partially Scanned', order_count: 0},
  {name: 'Scanned', text: 'Scanned', order_count: 0}
];

export const excludedTagData = [
  {name: 'Cancelled', text: 'Cancelled', not_present_in_order_count: 0},
  {name: 'ServiceIssue', text: 'Service Issue', not_present_in_order_count: 0},
  {name: 'OnHold', text: 'On Hold', not_present_in_order_count: 0}
];

export const statusesData = [
  {id: 'Awaiting', label: 'Awaiting'},
  {id: 'Scanned', label: 'Scanned'},
  {id: 'ServiceIssue', label: 'Service Issue'},
  {id: 'Cancelled', label: 'Cancelled'},
  {id: 'PartiallyScanned', label: 'Partially Scanned'},
  {id: 'OnHold', label: 'Action Required'}
];

export const filterValueData = (initialStatus = '') => [
  { name: 'OrderNumber', operator: 'contains', type: 'string' },
  { name: 'Store', operator: 'contains', type: 'string' },
  { name: 'Notes', operator: 'contains', type: 'string' },
  { name: 'OrderDate', operator: 'before', type: 'date', value: '' },
  { name: 'Items', operator: 'gte', type: 'number' },
  { name: 'User', operator: 'contains', type: 'string' },
  {
    name: 'Status',
    operator: 'eq',
    type: 'string',
    value: initialStatus,
  },
  { name: 'Tags', operator: 'eq', type: 'string' },
  { name: 'Recipient', operator: 'contains', type: 'string' },
  {name: 'OSLMT', operator: 'before', type: 'date', value: ''},
  { name: 'customFieldOne', operator: 'contains', type: 'string' },
  { name: 'customFieldTwo', operator: 'contains', type: 'string' },
  { name: 'trackingNumber', operator: 'contains', type: 'string' },
  { name: 'country', operator: 'contains', type: 'string' },
  { name: 'city', operator: 'contains', type: 'string' },
  { name: 'email', operator: 'contains', type: 'string' },
  { name: 'tote', operator: 'contains', type: 'string' },
];

