import React from 'react';
import { View, TextInput, TouchableOpacity, StyleSheet } from 'react-native';
import CommonStyles from '../../../style/commonStyles';

export const SearchBar = ({
  searchData,
  handleChange,
  searchItem,
  searchField,
  windowWidth,
  onKeyPress,
  handleClearSearch,
}) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity testID="searchItemButton" style={styles.searchButton}>
        <i className="icon-search" style={styles.searchIcon}></i>
      </TouchableOpacity>

      <TextInput
        testID="searchItem"
        placeholder="Type Order to search"
        name="searchOrder"
        autoFocus={true}
        ref={searchField}
        value={searchData}
        onChangeText={handleChange}
        onSubmitEditing={searchItem}
        style={[CommonStyles.typeOrderInput, styles.searchInput]}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onKeyPress();
          }
        }}
      />

      {searchData && (
        <TouchableOpacity
          testID="closeSearch"
          style={[
            styles.closeButton,
            { marginLeft: windowWidth > 1440 ? '-8%' : '-11%' },
          ]}
          onPress={handleClearSearch}
        >
          <i className="icon-cancel-circled" style={styles.closeIcon}></i>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '105%',
    flexDirection: 'row',
    alignSelf: 'stretch',
  },
  searchButton: {
    flexDirection: 'row',
    backgroundColor: '#CCCDD0',
    height: 26,
    borderRadius: 10,
    width: '12%',
    marginLeft: 5,
    paddingLeft: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  searchIcon: {
    fontSize: 20,
    color: '#FFFFFF',
  },
  searchInput: {
    height: 26,
    marginLeft: -7,
    paddingLeft: 25,
    backgroundColor: '#CCCDD0',
    color: 'gray',
    width: '80%',
    borderRadius: 10,
  },
  closeButton: {
    marginTop: 1,
  },
  closeIcon: {
    fontSize: 17,
    color: 'gray',
  },
});

export default SearchBar;

