import React from "react";
import { View, Dimensions, ScrollView ,TouchableOpacity} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import HTML from "react-native-render-html";
import Clipboard from '@react-native-clipboard/clipboard';
const DisplayInstructions = ({ item, width, name }) => {
  return (
    <React.Fragment>
      {width > 900 ? (
        <View
          style={{
            textAlign: "left",
            backgroundColor: "#152b43",
          }}
        >
          <Instruction item={item} />
        </View>
      ) : (
        <ScrollView
          style={{
            textAlign: "left",
            backgroundColor: "#152b43",
          }}
        >
          <Instruction item={item} name={name} />
        </ScrollView>
      )}
    </React.Fragment>
  );
};
const Instruction = ({ item, name }) => {
  const copyToClipboard = () =>{
    const result = item.instruction.split('<hr/>')[1];
    const updatedStr = result.replace(/<\/h4>$/, '');
    console.log("updatedStr", updatedStr); 
    Clipboard.setString(updatedStr);
  }
  return (
    <React.Fragment>
      {item.instruction !== "" && (
        <View
          dataSet={{ componentName: name }}
          style={{
            zIndex: 10,
            width: "100%",
            justifyContent: "center",
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          <LinearGradient
            locations={[0, 0.5, 1]}
            colors={["#000000", "#1e1e1e", "#000000"]}
            style={{
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            {item.instruction !== "" && (
              <TouchableOpacity
                onPress={()=>{
                  copyToClipboard()
                }}
                style={{
                  padding: 5,
                  color: "white",
                  textShadowColor: "white",
                  textShadowOffset: {
                    width: 1,
                    height: 1,
                  },
                  textShadowRadius: 10,
                }}
              >
                {item.instruction !== "" && (
                  <HTML
                    html={`<div style="color: white; fontSize: 27px;line-height: 1.2;">${item.instruction}</div>`}
                    imagesMaxWidth={Dimensions.get("window").width > 768 ? (Dimensions.get("window").width/2) - 10 : Dimensions.get("window").width}
                  />
                )}
              </TouchableOpacity>
            )}
          </LinearGradient>
        </View>
      )}
    </React.Fragment>
  );
};
export default DisplayInstructions;