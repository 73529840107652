import React,{useState,useEffect} from 'react';
import {LinearGradient} from 'expo-linear-gradient';
import { fontFamily } from '../../helpers/fontFamily';
import { Image, Text, View,StyleSheet, TouchableOpacity } from 'react-native';
import i18n from 'i18n-js';
const DisplayImage = (props) => {
  const [showDefault, setShowDefault] = useState(false)
  let slider_image = [];
  useEffect(()=>{
  if(props.images.length < 1){
      setShowDefault(true)
      return;
  }
  setShowDefault(false)
  },[props.images[0]?.image])
  return (
    <View
      style={style.container}
    >
      <LinearGradient
        locations={[0, 0.04, 0.08]}
        start={{ x: 0.9, y: 1 }}
        colors={['#3d4851', '#9EA4A8', '#ffffff']}
        style={style.gradient}
      >
        <TouchableOpacity
          testID='menuIcon'
          onPress={props.updateShowMenu}
          style={style.iconContainer}
        >
          <i
              className="icon-menu"
              style={{color: 'gray', fontSize: 24, marginLeft: -10}}>
            </i>
        </TouchableOpacity>
        {props.images.map(
          (image, index) => {
            slider_image.push(image.image);
          }
        )}
        {showDefault ?(
          <Image
            onError={props.onError}
            source={props.source}
            style={style.image}
          />
        ) : (
          <Image
            source={{uri:slider_image[0]}}
            onError={()=>{
              setShowDefault(true)
            }}
            style={style.image}
          />
        )}
        <LinearGradient
          locations={[0, 0.04, 0.08]}
          start={{ x: 0.9, y: 1 }}
          colors={[
            '#3d4851',
            '#9EA4A8',
            '#ffffff'
          ]}
          style={style.gradient1}
        >
          <Text
            style={[
              {
                fontWeight: 'bold',
                fontFamily: fontFamily.font700,
                fontSize: 22,
                marginTop: 1,
                background:
                  '-webkit-linear-gradient( #5a758d, #324a64)',
                webkitBackgroundClip: 'text',
                webkitTextFillColor: 'transparent',
                lineHeight: 22,
              }]
            }
          >
            {props.scanned_qty} /{' '}
            {props.total_qty}
          </Text>
          <Text
            style={style.text}
          >
            {i18n.t("scanpackItem.scannedLabel")}
          </Text>
        </LinearGradient>
      </LinearGradient>
    </View>
  )
}
export default DisplayImage;

const style = StyleSheet.create({
  iconContainer: {
    padding: 10,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    top: '10%',
    zIndex: 1,
    width: '100%'
  },
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 166
  },
  gradient:{
    height: 165,
    width: 175,
    backgroundColor: '#000000',
    marginTop: 10,
    position: 'relative',
    zIndex: 9,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 18,
    borderBottomLeftRadius: 18,
    shadowColor: '#000000',
    shadowOpacity: 0.58,
    shadowRadius: 29,
    shadowOffset: {
      width: 0,
      height: 0
    }
  },
  image:{
    height: '100%',
    width: '94%',
    resizeMode: 'contain',
    borderTopLeftRadius: 18,
    borderBottomLeftRadius: 18,
    marginRight: 11,
    bottom: '15%'
  },
  text:{
    textAlign: 'center',
    fontSize: 12,
    color: '#000',
    fontFamily: fontFamily.font600,
    textTransform: 'uppercase',
    lineHeight: 13
  },
  gradient1: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    fontSize: 18,
    fontFamily:
      fontFamily.font600,
    backgroundColor: '#fff',
    borderBottomLeftRadius: 18,
    opacity: 0.7,
    bottom: 0,
    right: 0,
    left: 0,
    marginLeft: 'auto',
    marginRight: 0,
    zIndex: 999
  }
})
