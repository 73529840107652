import i18n from 'i18n-js';
import { submitTrackingLog } from './scanBarcodeFunctions';

export const handleOrderCompletion = (
  order_num,
  trackingNumber,
  strict_cc,
  order,
  componentContext,
  setStateCallback,
  localLogs,
  componentState
) => {
  let temp_order_completion = {
    id: order_num,
    input: trackingNumber,
    state: 'scanpack.rfp.no_tracking_info',
    event: 'verify',
    updated_at: order.order.updated_at,
    increment_id: order.order.increment_id,
    on_ex: 'on GPX'
  };
  localLogs.splice(0, 0, temp_order_completion);
  componentContext.updateLog(temp_order_completion, 'recordTracking');
  componentContext.responseAction('complete_order');
  componentContext.scannAllItems(
    componentContext.props?.Order?.basicinfo?.id
  );
  setStateCallback({ postScanSecond: false });

  if (
    trackingNumber === componentState.userInfo.confirmation_code ||
    (!strict_cc && trackingNumber === '')
  ) {
    componentContext.props.navigation.navigate("ScanPack");
  }
  return temp_order_completion
};

export function trackingSubmit(
    componentContext,
    componentState,
    scan_pack_settings,
    trackingNumber,
    order,
    localLogs,
    add_edit_order_items,
    general_settings,
    setStateCallback,
  ) {
    let trackState = ''
    let setting = scan_pack_settings;
    let tracking_no = trackingNumber?.trimStart();
    let trackingLength = tracking_no?.length;
    let temp_order = {};
    let num = '';
    let order_num = order.order.id;
    let orderConPer = add_edit_order_items;
    let strict_cc = general_settings.strict_cc;

    if (setting.post_scanning_option !== 'None') {
      if (componentState.timeSecond === true) {
        let type = setting.post_scanning_option_second;
        if (type === 'Record') {
          if (setting.tracking_number_validation_enabled) {
            let prefix = setting.tracking_number_validation_prefixes.split(',');
            let validate = '';
            let scan = false;
            let scanAccess = false;
            prefix.map((prefix, index) => {
              let prefixLength = prefix.length;
              validate = trackingNumber.slice(0, prefixLength);
              if (scanAccess === false) {
                if (validate === prefix) {
                  (temp_order = submitTrackingLog(order,'scanpack.rfp.default','record',trackingNumber))
                  localLogs.splice(0, 0, temp_order);
                  scan = true;
                  scanAccess = true;
                } else {
                  scan = false;
                }
              }
            });
            if (scan === true) {
              localLogs.splice(0, 0, temp_order);
              componentContext.updateLog(temp_order, 'recordTracking');
              componentContext.responseAction('complete_order');
              componentContext.scannAllItems(componentContext.props?.Order?.basicinfo?.id)
            } else {
              componentContext.responseAction('scan_fail');
              componentContext.alertBoxOpenTracking(
                i18n.t('scanpackItem.Scanpack_Item_tracking_msg'),
                true
              );
            }
          } else if(trackingLength ===0 ){
            componentContext.responseAction('scan_fail')}
            else{
              (temp_order = submitTrackingLog(order,'scanpack.rfp.default','record',trackingNumber))
              localLogs.splice(0, 0, temp_order);
              componentContext.updateLog(temp_order, 'recordTracking');
              componentContext.responseAction('complete_order');
              componentContext.scannAllItems(componentContext.props?.Order?.basicinfo?.id)
              componentContext.props.navigation.navigate("ScanPack")
            }
        }
        if (type === 'Verify') {
          let trackNumber = order.order.tracking_num;
          if (trackNumber !== null) {
            let scan = false;
            if (componentState.scanSecondTime === true) {
              if (
                trackingNumber === componentState.userInfo.confirmation_code ||
                (strict_cc === false && trackingNumber === '')
              ) {
                if (!orderConPer) {
                  setStateCallback({ orderConfirmationPer: true });
                } else {
                  setStateCallback({ alertBoxShipping: false });
                  scan = true;
                }
              }
            }
            if (trackingNumber === trackNumber || scan === true) {
              temp_order['id'] = order_num;
              temp_order['input'] = trackingNumber;

              temp_order['state'] =
                componentState.trackingStateSecond === 'scanpack.rfp.no_match'
                  ? 'scanpack.rfp.no_match'
                  : 'scanpack.rfp.verifying';
              temp_order['event'] = 'regular';
              temp_order['updated_at'] = order.order.updated_at;
              temp_order['increment_id'] = order.order.increment_id;
              localLogs.splice(0, 0, temp_order);
              componentContext.updateLog(temp_order, 'recordTracking');
              setStateCallback({ alertBoxShipping: false });
              componentContext.responseAction('complete_order');
              componentContext.scannAllItems(componentContext.props?.Order?.basicinfo?.id)
              setStateCallback({ trackingStateSecond: '' });
              componentContext.props.navigation.navigate("ScanPack")
            } else {
              trackState = trackingNumber;
              componentContext.responseAction('scan_fail');
              componentContext.alertBoxOpenShipping(
                i18n.t('scanpackItem.Scanpack_Item_shipping_msg_two'),
                true
              );
              setStateCallback({
                trackingStateSecond: 'scanpack.rfp.no_match',
                scanSecondTime: true,
                postScanningMessageDetail: i18n.t("scanpackItem.trackingSubmit")
              });
            }
          } else {
            let message = '';
            if (
              order.order.tracking_num !== null &&
              order.order.tracking_num !== ''
            ) {
              message = i18n.t("scanpackItem.trackingSubmit")
            } else {
              setStateCallback({ verifylabel: true });
              message = i18n.t('shippinglabelmsg.noRecordTracking');
            }
            if (componentState.postScanSecond) {
              if (
                trackingNumber === componentState.userInfo.confirmation_code ||
                (strict_cc === false && trackingNumber === '')
              ) {
                temp_order = handleOrderCompletion(order_num, trackingNumber, strict_cc, order, componentContext, setStateCallback, localLogs, componentState);
                componentContext.props.navigation.navigate("ScanPack")
              } else {
                if (strict_cc === true) {
                  temp_order = handleOrderCompletion(order_num, trackingNumber, strict_cc, order, componentContext, setStateCallback, localLogs, componentState);
                }
              }
            }
            setStateCallback({
              postScanSecond: true,
              postScanningMessageDetail: message
            });
          }
        }
      } else {
        let type = setting.post_scanning_option;
        if (type === 'Record') {
          if (setting.tracking_number_validation_enabled) {
            let prefix = setting.tracking_number_validation_prefixes.split(',');
            let validate = '';
            let scan = false;
            let scanAccess = false;
            prefix.map((prefix, index) => {
              let prefixLength = prefix.length;
              validate = trackingNumber.slice(0, prefixLength);
              if (scanAccess === false) {
                if (validate === prefix) {
                  (temp_order = submitTrackingLog(order,'scanpack.rfp.default','record',trackingNumber))
                  scan = true;
                  scanAccess = true;
                } else {
                  scan = false;
                }
              }
            });
            if (scan === true) {
              localLogs.splice(0, 0, temp_order);
              componentContext.updateLog(temp_order, 'recordTracking');
              if (
                setting.post_scanning_option_second !== 'None' &&
                setting.post_scanning_option_second !==
                setting.post_scanning_option
              ) {
                componentContext.postScanFunction(
                  setting.post_scanning_option_second,
                  true
                );
                setStateCallback({ trackingOrderInput: '' });
                componentContext.updateLog(temp_order, 'tracking');
              } else {
                componentContext.updateLog(temp_order, 'recordTracking');
              }
            } else {
              componentContext.responseAction('scan_fail');
              componentContext.alertBoxOpenTracking(
                i18n.t('scanpackItem.Scanpack_Item_tracking_msg'),
                true
              );
            }
          } else if (trackingNumber === '' || trackingNumber === null) {
            componentContext.responseAction('scan_fail');
          } else {
            (temp_order = submitTrackingLog(order,'scanpack.rfp.default','record',trackingNumber))
            if (
              setting.post_scanning_option_second !== 'None' &&
              setting.post_scanning_option_second !==
              setting.post_scanning_option
            ) {
              componentContext.postScanFunction(setting.post_scanning_option_second, true);
              setStateCallback({ trackingOrderInput: '' });
              componentContext.updateLog(temp_order, 'tracking');
            } else {
              localLogs.splice(0, 0, temp_order);
              componentContext.updateLog(temp_order, 'recordTracking');
              componentContext.responseAction('complete_order');
              componentContext.scannAllItems(componentContext.props?.Order?.basicinfo?.id)
            }
          }
        }
        if (type === 'Verify') {
          let conditionCheck = false;
          if (order.order.tracking_num !== null) {
            let scan = false;
            if (componentState.scanSecondTime === true) {
              if (
                trackingNumber === componentState.userInfo.confirmation_code ||
                (strict_cc === false && trackingNumber === '')
              ) {
                if (!orderConPer) {
                  setStateCallback({ orderConfirmationPer: true });
                } else {
                  setStateCallback({ alertBoxShipping: false });
                  scan = true;
                }
              }
            }
            if (
              trackingNumber.length > order.order.tracking_num.length ||
              trackingNumber.length === order.order.tracking_num.length
            ) {
              let tempNum = trackingLength - order.order.tracking_num.length;
              num = trackingNumber.slice(tempNum);
              setStateCallback({ alertBoxShipping: false });
            }
            if (num === order.order.tracking_num || scan === true) {
              trackState = trackingNumber;
              temp_order['id'] = order_num;
              temp_order['input'] = trackingNumber;
              temp_order['state'] =
                componentState.trackingState === 'scanpack.rfp.no_match'
                  ? 'scanpack.rfp.no_match'
                  : 'scanpack.rfp.verifying';
              temp_order['event'] = 'verify';
              temp_order['updated_at'] = order.order.updated_at;
              temp_order['increment_id'] = order.order.increment_id;
              temp_order['on_ex'] = 'on GPX'
              if (
                setting.post_scanning_option_second !== 'None' &&
                setting.post_scanning_option_second !==
                setting.post_scanning_option
              ) {
                componentContext.postScanFunction(
                  setting.post_scanning_option_second,
                  true
                );
                componentContext.updateLog(temp_order, 'tracking');
              } else {
                localLogs.splice(0, 0, temp_order);
                componentContext.updateLog(temp_order, 'recordTracking');
              }
            } else {
              trackState = trackingNumber;
              componentContext.alertBoxOpenShipping(
                i18n.t('scanpackItem.Scanpack_Item_shipping_msg_two'),
                true
              );
              componentContext.responseAction('scan_fail');
              setStateCallback({
                trackingState: 'scanpack.rfp.no_match',
                scanSecondTime: true,
                postScanningMessageDetail: i18n.t("scanpackItem.trackingSubmit")
              });
            }
          } else {
            let message = '';
            if (
              order.order.tracking_num !== null &&
              order.order.tracking_num !== ''
            ) {
              message = i18n.t("scanpackItem.trackingSubmit")
            } else {
              setStateCallback({ postScan: true });
              conditionCheck = true;
              setStateCallback({ verifylabel: true });
              message = i18n.t('shippinglabelmsg.noRecordTracking');
            }
            if (conditionCheck) {
              if (
                trackingNumber === componentState.userData.confirmation_code ||
                (strict_cc === false && trackingNumber === '') || strict_cc === true
              ) {
                (temp_order = submitTrackingLog(order,'scanpack.rfp.no_tracking_info','verify',trackingNumber))
                if (
                  setting.post_scanning_option_second !== 'None' &&
                  setting.post_scanning_option_second !==
                  setting.post_scanning_option
                ) {
                  componentContext.postScanFunction(
                    setting.post_scanning_option_second,
                    true
                  );
                  setStateCallback({ postScan: false });
                  componentContext.updateLog(temp_order, 'tracking');
                  setStateCallback({ verifylabel: false });
                } else {
                  localLogs.splice(0, 0, temp_order);
                  componentContext.updateLog(temp_order, 'recordTracking');
                  setStateCallback({ postScan: false });
                }
              }
            }
            setStateCallback({ postScan: true });
          }
        }
      }
    }
  setStateCallback({ trackingOrderInput: '' });
  return trackState;
}
