export const prepareTagPriorities = sortedTagPriorities => {
  let userTagsPriorities = [...sortedTagPriorities];
  userTagsPriorities = userTagsPriorities.filter(
    tag => tag?.is_user_card === true
  );
  userTagsPriorities.push({
    ...userTagsPriorities[userTagsPriorities.length - 1],
    addButton: true,
    is_user_card: true,
    id: 100001
  });

  let priorityTags = [...sortedTagPriorities];
  priorityTags = priorityTags.filter(tag => tag?.is_user_card === false);

  const nonStandByTags = priorityTags.filter(tag => tag?.is_stand_by === false);
  const standByTags = priorityTags.filter(tag => tag?.is_stand_by === true);

  priorityTags = [
    ...nonStandByTags,
    {
      ...priorityTags[priorityTags.length - 1],
      addButton: true,
      id: 100001
    },
    ...standByTags
  ];

  return {
    userTagsPriorities,
    priorityTags
  };
};

export const handleDataUpdate = (data, initialData, lastIndex) => {
  if (lastIndex) {
    return handleUserCardPositionUpdate(data, lastIndex);
  } else {
    const separatorIndex = data.findIndex(item => item.addButton);
    const originalData = [...initialData];
    const originalStandbyItems = originalData.filter(
      item => item.is_stand_by && !item.addButton
    );
    const originalNonStandbyItems = originalData.filter(
      item => !item.is_stand_by && !item.addButton
    );

    const newNonStandbyItems = data
      .slice(0, separatorIndex)
      .filter(item => !item.addButton);
    const newStandbyItems = data
      .slice(separatorIndex + 1)
      .filter(item => !item.addButton);

    const movedToStandby = findMovedItem(newStandbyItems, originalStandbyItems);
    const movedToNonStandby = findMovedItem(
      newNonStandbyItems,
      originalNonStandbyItems
    );

    const updatedData = [
      ...assignPositions(newStandbyItems, true),
      ...assignPositions(newNonStandbyItems, false, newStandbyItems.length)
    ];

    const positionUpdateData = {
      updates: updatedData.map(item => ({
        id: item.id,
        new_position: item.position
      }))
    };

    const attributeUpdate = generateAttributeUpdate(
      updatedData,
      movedToStandby,
      movedToNonStandby
    );

    return {
      attributeUpdate,
      positionUpdateData
    };
  }
};

const generateAttributeUpdate = (
  updatedData,
  movedToStandby,
  movedToNonStandby
) => {
  const movedItem = movedToStandby || movedToNonStandby;
  if (!movedItem) return null;

  const updatedItem = updatedData.find(item => item.id === movedItem.id);
  return {
    id: updatedItem.id,
    updatedData: {
      priority_card: {
        is_stand_by: movedToStandby ? true : false,
        position: updatedItem.position
      }
    }
  };
};

const findMovedItem = (newItems, originalItems) =>
  newItems.find(
    newItem => !originalItems.some(original => original.id === newItem.id)
  );

const assignPositions = (items, isStandby, offset = 0) =>
  items.map((item, index) => ({
    ...item,
    position: offset + index,
    is_stand_by: isStandby
  }));

const handleUserCardPositionUpdate = (data, lastIndex) => {
  const itemsForPositionUpdate = data.filter(item => !item.addButton);
  const updatedData = itemsForPositionUpdate.map((item, index) => ({
    ...item,
    position: lastIndex + index
  }));

  const positionUpdateData = {
    updates: updatedData.map(item => ({
      id: item.id,
      new_position: item.position
    }))
  };

  return {positionUpdateData};
};


export const getFilteredPriorityTags = (groovePacker, otherTags, selectedTag, isFirstLoad) => {
  const groovePackerResult = getFilteredTags(groovePacker, selectedTag, isFirstLoad);
  const otherTagsResult = getFilteredTags(otherTags, selectedTag, isFirstLoad);

  return {
    filteredGroovePackerTags: groovePackerResult.filteredTags,
    filteredOtherTags: otherTagsResult.filteredTags,
    selectedTagList: groovePackerResult.selectedTagList
  };
}
export const getFilteredUserTags = (userTags, selectedTag, isFirstLoad) => {
  const { filteredTags, selectedTagList } = getFilteredTags(userTags, selectedTag, isFirstLoad);

  return {
    filteredUserTags: filteredTags,
    selectedTagList
  };
}

const getFilteredTags = (tags, selectedTag, isFirstLoad) => {
  if (!isFirstLoad) {
    return {
      filteredTags: tags,
      selectedTagList: []
    };
  }

  return {
    filteredTags: tags.filter(tag => tag !== selectedTag),
    selectedTagList: selectedTag ? [selectedTag] : []
  };
};
