import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Vibration } from 'react-native';
import { fontFamily } from '../helpers/fontFamily';
import OrderItem from './orderItem';
import OrderItemImage from './orderItemImage';
import * as Clipboard from 'expo-clipboard'
import { useSelector } from 'react-redux';

const OrderKitItem = ({
  index,
  redirectToItemDetail,
  item,
  windowWidth,
  shuffleTheItem,
  shuffleTheKitItem,
  testID,
  listType
}) =>
  {
    const copyTextToClipboard = async (text) => {
      await Clipboard.setStringAsync(`${text}`);
    };
    const isHaptic = useSelector((state) => state.menu.isHaptics);
    return (
      <View
        dataSet = {{componentName:'kitParent'}}
        key={item?.id}
        style={styles.container}
      >
        <View
          dataSet = {{componentName: 'kit'}}
          style={styles.kitContainer}
        >
          <View
            style={styles.kitSubContainer}
          >
            <View
              style={[styles.kitLabelContainer, {
                width: windowWidth > 700 ? '80%' : '60%',
              }]}
            >
              <View
                style={styles.kitLabelSubContainer}
              />
              <Text
                style={styles.kitText}
              >
                KIT
              </Text>
              <View
                style={styles.kitLabelSubContainer}
              />
            </View>
            <TouchableOpacity
              testID='redirectToKitItem'
              onPress={() => {redirectToItemDetail(item)}}
              delayLongPress={1000}
            >
              <Text
                numberOfLines={2}
                ellipsizeMode="tail"
                style={ 
                  [styles.KitNameText,{fontSize: item.name.length <= 32 ? 18 : 16}]
                }
              >
                {item.name}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
             testID='copySKU'
             onPress={()=>{
              isHaptic && Vibration.vibrate(30)
              copyTextToClipboard(item.sku)
            }}
            >
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={styles.kitSkuLabel}
              >
                <Text
                  style={styles.kitSkuText}
                >
                  SKU :{' '}
                </Text>
                {item.sku}
              </Text>
            </TouchableOpacity>
          </View>
          <OrderItemImage 
           item={item}
           shuffleTheItem={(sku)=>{shuffleTheItem(sku)}}
           testID={'shuffleKitItem'}
           listType={listType}
          />
        </View>
        {item &&
          item.child_items &&
          item.child_items.length > 0 &&
          item.child_items.map((childItem, index) => {
            return (
              <View
                dataSet = {{componentName: `childItem-${index}`}}
                key={index}
                style={styles.kitChildItemContainer}
              >
                <OrderItem
                  item={childItem}
                  redirectToItemDetail={(item) => {redirectToItemDetail(item)}}
                  testID={'redirectKitChildItem'}
                />
                <OrderItemImage
                  item={childItem}
                  shuffleTheItem={(childSku) => {shuffleTheKitItem(item.sku, childSku) }}
                  testID={'shuffleKitChild'}
                  listType={listType}
                />
              </View>
            );
          })}
      </View>
    );
  };
const styles = StyleSheet.create({
  container:{
    margin: 1,
    borderRadius: 20,
    borderWidth: 3,
    borderColor: 'black',
    backgroundColor: '#3f5163',
    padding: 5,
    borderWidth: 2,
    borderColor: '#7c95ae',
  },
  kitContainer: {
    flexDirection: 'row',
    position: 'relative'
  },
  kitSubContainer: {
    width: '100%',
    minHeight: 118,
  },
  kitLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    top: -7,
    left: '2%',
  },
  kitLabelSubContainer:{
    width: '50%',
    height: 2,
    backgroundColor: '#fff'
  },
  kitText:{
    fontSize: 18,
    fontFamily: fontFamily.font500,
    color: '#fff'
  },
  KitNameText:{
    fontFamily: fontFamily.font400,
    width: '65%',
    textAlign: 'left',
    color: '#fff',
  },
  kitSkuLabel:{
    fontSize: 18,
    fontFamily: fontFamily.font700,
    color: '#fff',
    width: '65%',
    textAlign: 'left'
  },
  kitSkuText:{
    fontSize: 12,
    fontFamily: fontFamily.font400,
    fontWeight: 'bold'
  },
  kitChildItemContainer: {
    flexDirection: 'row',
    position: 'relative',
    backgroundColor: '#3f5163',
    textAlign: 'left',
    display: 'flex',
    marginTop: 5,
  }

})
export default OrderKitItem;