import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  Modal
} from 'react-native';
import ColorPicker from 'react-native-wheel-color-picker';
import {LinearGradient} from 'expo-linear-gradient';
import { AddTagsToOrder, GetAllTags, ResetOrdersTagsUpdated } from '../../../actions/orderActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';



const ListItem = ({item, onEdit, editingItemId, setEditingItemId, setIsPickerModalVisible, maxWidth = '100%'}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(item.name);
  const [editedColor, setEditedColor] = useState(item.color);
  const [isVisible, setIsVisible] = useState(item.isVisible);
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const {add_tag_success} =  useSelector(state => state.order);
  const dispatch = useDispatch();


  const handleSave = async (hasUpdatedVisiblety = false) => {
    const newItem = {
      id: item.id,
      name: editedName,
      color: editedColor,
      isVisible: hasUpdatedVisiblety ? !isVisible : isVisible
    };
    onEdit(newItem);
    setIsEditing(false);
    setEditingItemId(null);

    // Call the API to add tags to the order
    try {
      dispatch(AddTagsToOrder(newItem, {}, handleTagSuccess));
    } catch (error) {
      console.error('Failed to save tags to order:', error);
    }
  };

  const updateVisiblety = () => {
    setIsVisible(!isVisible);
    handleSave(true)
  }

  useEffect(() => {
    // if(item.id){
      setIsEditing(item.id === editingItemId);
    // }
  }, [editingItemId, item.id]);

  const handleTagSuccess = () => {
     dispatch(ResetOrdersTagsUpdated());
     dispatch(GetAllTags());
  }
  return (
    <View key={item.id} style={styles.listItem}>
      <View style={[styles.itemContainer,{maxWidth: maxWidth}]}>
        {isEditing ? (
          <>
            <TouchableOpacity testID='setVisibletyBtn' onPress={() => setIsVisible(!isVisible)}>
              <i
                className={`${isVisible ? 'icon-eye' : 'icon-eye-off'}`}
                style={styles.icon}></i>
            </TouchableOpacity>
            <TextInput
              autoFocus
              style={styles.itemInput}
              value={editedName}
              onChangeText={setEditedName}
              onSubmitEditing={() => handleSave()}
            />
            <TouchableOpacity
              style={[
                styles.colorPreview,
                {
                  backgroundColor: editedColor,
                  width: 40,
                  alignItems: 'center',
                  height: 17,
                  boxShadow: `0px 0px 4px 1px ${editedColor}`
                }
              ]}
              testID='EditColourBtn'
              onPress={() => {
                setIsColorPickerVisible(true);
                setIsPickerModalVisible(true);
              }}
            >
              <i className="icon-edit" style={styles.icon}></i>
            </TouchableOpacity>
          </>
        ) : (
          <>
            <TouchableOpacity testID='updateVisibletyBtn' style={[styles.item,{maxWidth: maxWidth}]} onPress={updateVisiblety}>
              <i
                className={`${isVisible ? 'icon-eye' : 'icon-eye-off'}`}
                style={styles.icon}></i>
              <Text style={[styles.item,{maxWidth: maxWidth}]} numberOfLines={1}>{item.name}</Text>
            </TouchableOpacity>
            <View
              style={[
                styles.colorBox,
                {
                  backgroundColor: item.color,
                  width: isEditing ? 12 : 5,
                  boxShadow: `0px 0px 4px 1px ${item.color}`
                }
              ]}
            />
          </>
        )}
      </View>
      {isEditing ? (
        <TouchableOpacity testID='circledIcon' onPress={() => handleSave()}>
          <i
            className="icon-ok-circled"
            style={{color: '#4FDD9F', fontSize: 15}}></i>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity  testID='editIcon' onPress={() => setEditingItemId(item.id)}>
          <i className="icon-edit" style={styles.icon}></i>
        </TouchableOpacity>
      )}
      <Modal
        visible={isColorPickerVisible}
        animationType="slide"
        transparent={true}>
        <TouchableOpacity
          style={styles.overlay}
        >
          <View style={styles.colorPickerContainer}>
            <LinearGradient
              colors={['#2F2F2F', '#000000']}
              style={styles.gradient}>
              <ColorPicker
                color={editedColor}
                onColorChange={setEditedColor}
                onColorChangeComplete={setEditedColor}
                thumbSize={20}
                sliderSize={20}
                noSnap={true}
                row={false}
                style={styles.colorPicker}
              />
              <TouchableOpacity
                testID='colorPickerbtn'
                onPress={() => {
                  setIsColorPickerVisible(false);
                  setIsPickerModalVisible(false);
                  handleSave();
                }}
                style={styles.saveButtonContainer}>
                <Text style={styles.saveButton}>Save changes</Text>
              </TouchableOpacity>
            </LinearGradient>
          </View>
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '10vw'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    paddingVertical: 10
  },
  headerText: {
    color: 'white',
    fontFamily: 'Poppins_300Light'
  },
  icon: {
    alignSelf: 'center',
    fontSize: 18,
    color: 'white'
  },
  dropdown: {
    maxHeight: '60vh'
  },
  content: {
    padding: 10
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  item: {
    color: 'white',
    fontFamily: 'Poppins_600SemiBold',
    paddingVertical: 5,
    flexDirection: 'row',
  },
  itemInput: {
    color: 'white',
    fontFamily: 'Poppins_300Light',
    width: '70%'
  },
  colorPreview: {
    width: 5,
    height: 13,
    borderRadius: 5,
    marginLeft: 10
  },
  colorBox: {
    width: 10,
    height: 20,
    borderRadius: 5,
    marginLeft: 10
  },
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  colorPickerContainer: {
    width: 250,
    borderRadius: 10,
    overflow: 'hidden'
  },
  gradient: {
    padding: 15,
    borderRadius: 10,
    alignItems: 'center'
  },
  colorPicker: {
    width: '100%',
    height: 150
  },
  saveButtonContainer: {
    marginTop: 10,
    paddingVertical: 5,
    paddingHorizontal: 15,
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 5
  },

  saveButton: {
    marginRight: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fff',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    shadowColor: '#2bfac3',
    shadowOffset: {width: 0, height: 0.5},
    shadowRadius: 8,
    elevation: 5,
    shadowOpacity: 0.5,
    color: 'white',
    padding: 5,
    fontFamily: 'Poppins_300Light'
  },
  closeButtonText: {
    color: 'white',
    fontWeight: 'bold'
  }
});

export default ListItem;
