import React, {useState, useEffect} from 'react';
import {Text, View, TouchableOpacity, FlatList, StyleSheet} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import i18n from 'i18n-js';
import {useSelector} from 'react-redux';
import en from '../../helpers/textFile';
import es from '../../helpers/es';

i18n.translations = {en, es};
i18n.fallbacks = true;

export default function LanguageToggler({setIsLanguageChange}) {
  const user = useSelector(state => state.user.userInfo);
  const {current_tenant,id} = user;

  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const languages = [
    {label: i18n.t('directprinting.english'), value: 'en'},
    {label: i18n.t('directprinting.spanish'), value: 'es'}
  ];

  useEffect(() => {
    const loadLanguage = async () => {
      try {
        const savedLanguage = await AsyncStorage.getItem(
          `language_${current_tenant}_${id}`
        );
        if (savedLanguage) {
          i18n.locale = savedLanguage;
          setSelectedLanguage(savedLanguage);
        }
      } catch (error) {
        console.error('Failed to load language:', error);
      }
    };

    loadLanguage();
  }, [current_tenant]);

  const changeLanguage = async lang => {
    try {
      i18n.locale = lang;
      setSelectedLanguage(lang);
      setIsDropdownOpen(false);
      setIsLanguageChange(prev => !prev);
      await AsyncStorage.setItem(`language_${current_tenant}_${id}`, lang);
    } catch (error) {
      console.error('Failed to save language:', error);
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {i18n.t('directprinting.selectLanguage')}
      </Text>

      <TouchableOpacity
        testID='languageToggler'
        onPress={() => setIsDropdownOpen(!isDropdownOpen)}
        style={styles.dropdownButton}>
        <Text style={styles.dropdownText}>
          {selectedLanguage === 'en' ? 'English' : 'Español'}
        </Text>
      </TouchableOpacity>

      {isDropdownOpen && (
        <View style={styles.dropdownContainer}>
          <FlatList
            data={languages}
            keyExtractor={item => item.value}
            renderItem={({item}) => (
              <TouchableOpacity
                testID={`language-${item.value}`}
                onPress={() => changeLanguage(item.value)}
                style={styles.dropdownItem}>
                <Text
                  style={[
                    styles.itemText,
                    selectedLanguage === item.value && styles.selectedText
                  ]}>
                  {item.label}
                </Text>
              </TouchableOpacity>
            )}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    marginTop: 20
  },
  heading: {
    color: 'white',
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 10
  },
  dropdownButton: {
    width: 200,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    padding: 10,
    backgroundColor: '#fff'
  },
  dropdownText: {
    color: '#000'
  },
  dropdownContainer: {
    position: 'absolute',
    top: 90,
    zIndex: 1,
    width: 200,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    elevation: 5
  },
  dropdownItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#eee'
  },
  itemText: {
    color: '#000'
  },
  selectedText: {
    fontWeight: 'bold',
    color: '#1CBB77'
  }
});
