import React, {useLayoutEffect, useState} from 'react';
import {View, Text, TouchableOpacity, Dimensions} from 'react-native';
import styles from '../../style/popupmodel';
import {LinearGradient} from 'expo-linear-gradient';
import ReScanOrderPopup from './rescan_order_popup';
import i18n from 'i18n-js';

export default function RescanPopup(props) {
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get('window').width
  );
  let directPrintLabelStateNew = false
  const newval =
    props?.basicInfo === undefined ? props?.newState : props?.basicInfo;

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  const redirectShipping = () => {
    let shipmentData = props?.ssLabelData?.ss_label_data?.shipments?.filter(
      z => z.voided === false
    );
    let ssLabelData = props?.ssLabelData?.ss_label_data?.shipping_labels
    const matchingItem2Array = []
    {shipmentData?.map((shipmentinsideData, index) => {
      let matchingItem2 = ssLabelData.find(
        shipmentData =>
          shipmentinsideData.shipmentId ===
          shipmentData.shipment_id
          );
   matchingItem2Array.push(matchingItem2);
    })}
    if(matchingItem2Array && matchingItem2Array?.length > 0){
      directPrintLabelStateNew=true
    }

     props?.navigation?.navigate('ShippingLabel', {
     basicinfo: newval?.scan_hash?.data?.order,
     directPrintLabelState:directPrintLabelStateNew,
     userRole: props?.userRole,
     isolatedComponent: true
   });
    props?.closePopup();
  };

  return (
    <>
      {props?.reScanOrderPopupShow && (
        <ReScanOrderPopup
          popupHead={i18n.t("scanPack.shouldInventoryAdjust")}
          preOrderRescan={i18n.t("scanPack.noReScanItems")}
          newItemQues={i18n.t("scanPack.yesPullInventory")}
          closePopupRescan={props?.closePopupRescan}
          newOrderApear={props?.newOrderApear}
          awaitOrderDataFun={props?.awaitOrderDataFun}
          basicInfo={newval?.id}
          newProps={props?.newProps}
        />
      )}
      {props.reScanPopupShow && (
        <View style={styles.rescanOrderMainView}>
          <LinearGradient
            locations={[0, 1]}
            colors={['#FFB538', '#CF7C08']}
            style={
              windowWidth >= 900
                ? styles.linearGradMainView
                : styles.linearGradMainViewMobile
            }>
            <TouchableOpacity
              testID="model-close"
              style={{height: 25, width: 34, marginLeft: 'auto'}}
              onPress={() => props?.closePopup()}>
              <i
                className="icon-cancel"
                style={{
                  color: '#000',
                  fontSize: '24px'
                }}></i>
            </TouchableOpacity>
            <View>
              <Text style={styles.alreadyScanHead}>
                {props?.headingmessage}
              </Text>
            </View>
            <View>
              <Text style={styles.alreadyScanSubHead}>{props?.subHeading}</Text>
            </View>
            <View
              style={
                windowWidth >= 900
                  ? styles.buttonMainView
                  : styles.buttonMainViewMobile
              }>
              <TouchableOpacity
                style={styles.buttonBox}
                testID="model-close"
                onPress={() => props?.closePopup()}>
                <View>
                  <Text style={styles.buttonTextOrderRescan}>{props?.cancelButton}</Text>
                </View>
              </TouchableOpacity>
              {newval?.use_api_create_label && (
                <TouchableOpacity
                  testID="redirect-ss"
                  onPress={() => redirectShipping()}
                  style={styles.buttonBox}>
                  <View>
                    <Text style={styles.buttonTextOrderRescan}>{props?.rePrintBtn}</Text>
                  </View>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                testID="Redirect-to-another"
                onPress={() => props?.redirectToAnother()}
                style={styles.buttonBox}>
                <View>
                  <Text style={styles.buttonTextOrderRescan}>{props?.reScanBtn}</Text>
                </View>
              </TouchableOpacity>
            </View>
          </LinearGradient>
        </View>
      )}
    </>
  );
}
