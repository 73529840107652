import React from 'react';
import { View,Text,TouchableOpacity,StyleSheet,Image, Platform } from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';

import {fontFamily} from '../helpers/fontFamily';
import BootButtonComponent from '../components/bootButton';
import ProgressBar from './progressBar';
import I18n from 'i18n-js';
// import SyncButton from '../components/actionButton/sync';
function CustomHeader(props) {

  const windowWidth = props.windowWidth;
  const isTitleLink = props.isTouchableTitle ? true : false
  const getHeight = () => {
    if(Platform.OS === 'ios' || window.navigator.platform === 'iPhone') {
      if(windowWidth < 900 && window.navigator.standalone) {
        return 85
      }
    }
    if(windowWidth < 900) {
      return 40
    }
    return props.height ? props.height : 64
  }
  return (
    <LinearGradient
      start={{x: 0, y: 1} }
      end={{x: 0, y: 0}}
      colors={['#142130', '#304454']}
      style={styles.backgroundContainer}
    >
      <View
      style={[
        styles.container,{height: getHeight(), alignItems: windowWidth < 900 ? 'flex-end' : 'center'}
      ]}
      >
        <View
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
        >
          <TouchableOpacity
          testID='menuIcon'
          onPress={props.onPress}
          style={styles.iconContainer}>
            <i
              className="icon-menu"
              style={{color: 'white', fontSize: 24, marginLeft: -10}}>
            </i>
          </TouchableOpacity>
          {windowWidth > 900 &&
            <View
            testID='userDetail'
            style={styles.userDetailContainer}
            >
              <Text style={styles.username} >
                {props?.username}
              </Text>
              <Text style={styles.tenant} >
                {props?.tenant}
              </Text>
            </View>
          }
        </View>

        <View>
          {!isTitleLink ?
            <Text style={[styles.title, {marginRight: windowWidth < 900 ? '3rem' : '4rem'}]} >
              {props.title}
            </Text>
          :
            <>
            {props.alertShow === false ? (
              <TouchableOpacity
                testID='Title'
                onPress={props.onPressTitle}>
                <Text style={styles.title} >
                  {props.title}
                </Text>
              </TouchableOpacity>
            ):(
              <LinearGradient
                colors={['#5bbc31', '#479523', '#3f851d']}
                testID='alertMessage'
                style={styles.backgroundContainer1}>
                <Text
                  style={
                    windowWidth > 1024 ? {fontSize: 16} : {fontSize: 13}
                  }>
                  {I18n.t("nextItems.changesSaveLabel")}
                </Text>
                <TouchableOpacity
                  onClick={props.onClick}
                  style={{position: 'absolute', right: 11}}>
                  <Image
                    style={
                      windowWidth > 1024
                        ? {width: 22, height: 22}
                        : {width: 18, height: 18}
                    }
                    source={props.Close ? props.Close : ''}
                  />
                </TouchableOpacity>
              </LinearGradient>
            )}
            </>
          }
        </View>

        <View style={[{marginRight: '1%'}]}>
          {/* {props.showSync ?
          <SyncButton
            syncButtonHandle={props.syncButtonHandle}
            alertShow={props.alertShow}
          />:<></>} */}
          {windowWidth > 1024 && <BootButtonComponent />}
        </View>

      </View>
      {/* {console.log(">>>>>>>>", props.progressCount)} */}
      {props.progressCount ? <ProgressBar progress={props.progressCount} actionMsg={props.actionMsg}></ProgressBar> : <></>}
    </LinearGradient>
  );
}

export default CustomHeader;

const styles = StyleSheet.create({
  container : {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems:'flex-end',
    paddingBottom: 2
  },
  iconContainer: {
    flex: 1,
    marginLeft: 20,
    justifyContent: 'center',
    width: '100%'
  },
  username: {
    fontFamily: fontFamily.font500,
    color: '#fff',
    fontSize: 16,
    textTransform: 'uppercase',
    lineHeight: 15,
    marginTop: 4
  },
  tenant: {
    fontFamily: fontFamily.font700,
    color: '#fff',
    fontSize: 16,
    textTransform: 'uppercase'
  },
  title: {
    color: '#fff',
    fontFamily: fontFamily.font700,
    fontSize: 18
  },
  userDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10
  },
  backgroundContainer: {
    backgroundColor: '#707070',
    width: '100%',
    height: '100%'
  },
  backgroundContainer1: {
    position: 'fixed',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 4,
    marginTop: 12,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 3.84,
    shadowColor: '#000',
    shadowOpacity: 0.9,
    zIndex: 9,
    width: '80%',
    borderRadius: 5
  }
})
